import { HideTgInterface } from "feature";
import { Button, Flex, Typography } from "antd";
import { useTokenContext, useCopyToClipboard, useScanQrPopup, useShowConfirm, AuthService } from "shared";
import { useState } from "react";

export const Setting = () => {
  const token = useTokenContext();
  const [_, copy] = useCopyToClipboard();
  const [showScaner, closeScaner] = useScanQrPopup();
  const showPopUp = useShowConfirm();

  const  afterScan = (text: string) => {
    closeScaner();
    showPopUp("Авторизовать это устройство?", async (isOk: boolean) => {
      if (isOk) {
        await AuthService.postApiAuthQrcodeConfirm({ sessionId: text });
      }
    });
  }

  return (
    <Flex vertical>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Настройки
      </Typography.Title>
      <Button
        className="w-full mt-7"
        type="primary"
        onClick={() => showScaner({ text: "Сканируйте для авторзации" }, (text: string) => afterScan(text))}
        size="large"
      >
        Авторизоваться по QR коду
      </Button>
      <Button
        className="w-full mt-7"
        type="primary"
        onClick={() => copy(token?.token)}
        size="large"
      >
        Скопировать токен
      </Button>
      <HideTgInterface />
    </Flex>
  );
};
