import React, { useEffect, useState } from 'react';
import PullToRefresh from 'pulltorefreshjs';
import './index.scss';

const PullToRefreshComponent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isRefreshing, setIsRefreshing] = useState(false);

    useEffect(() => {
        PullToRefresh.init({
            mainElement: 'body',
            onRefresh: () => {
                setIsRefreshing(true);
                return new Promise<void>((resolve) => {
                    setTimeout(() => {
                        setIsRefreshing(false);
                        window.location.reload();
                        resolve();
                    }, 1000);
                });
            }
        });

        return () => {
            PullToRefresh.destroyAll();
        };
    }, []);

    return (
        <div>
            {isRefreshing && (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            )}
            {children}
        </div>
    );
};

export default PullToRefreshComponent;