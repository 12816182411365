import { Routing, HideTgInterface } from "feature";
import { SessionStates, DashboardWidget } from "widgets";

export const Main = () => {
  return (
    <>
      <DashboardWidget />
      <Routing />
      <SessionStates />
      <HideTgInterface />
    </>
  );
};
