import { Flex, DatePicker, Form, TableColumnsType, InputNumber, Slider, Select, Button, Table, notification, Alert, Input, Space, Switch } from "antd";
import { useEffect, useState } from "react";
import { RetroService, DictionaryService, BacktestReportResponse, BacktestReportResponsePosition } from "shared/api";
import { TokenDto, useShowPopup } from "shared";
import dayjs from 'dayjs';
import numeral from "numeral";


const { RangePicker } = DatePicker;
const { Column, ColumnGroup } = Table;

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface FormModel {
  dateInOutRange: Date[],
  datetimeInParam: string,
  datetimeOutParam: string,
  pairName: string,
  amountStable: number,
  ratio: number,
  token0Id: number,
  token1Id: number,
  channel: Array<number>,
  minChannelWidth: number,
  maxChannelWidth: number,
  exitTrigger: Array<number>,
  switchDirection: boolean,
}

interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
  dtOpen: string,
  dtClose: string,
  priceIn: number,
  priceOut: number,
  stableAmountIn: number,
  trend: string,
}

const mainColumns: TableColumnsType<BacktestReportResponse> = [
  {
    title: 'Channel width percent',
    dataIndex: 'channelWidth',
    //key: 'channelWidth',
  },
  {
    title: 'Amount Out',
    dataIndex: 'amountOut',
    sorter: (a, b) => (a.amountOut ?? 0) - (b.amountOut ?? 0),
    render: (value)=> numeral(value ?? 0).format("0,0.00"),
    //key: 'amountOut',
  },
  {
    title: 'Pool pstns',
    //key: 'positionList',
    render: (obj:BacktestReportResponse)=> obj.positionList?.length,
  },
]

const subColumns: TableColumnsType<BacktestReportResponsePosition> = [
  { title: 'DT Open', dataIndex: 'dtOpen', key: 'dtOpen', render: (value)=> dayjs(value ?? 0).format("DD.MM.YYYY HH:mm") },
  { title: 'DT Close', dataIndex: 'dtClose', key: 'dtClose', render: (value)=> dayjs(value ?? 0).format("DD.MM.YYYY HH:mm") },
  { title: 'PriceStart', dataIndex: 'priceIn', key: 'priceIn', render: (value)=> numeral(value ?? 0).format("0,0.00") },
  { title: 'PriceEnd', dataIndex: 'priceOut', key: 'priceOut', render: (value)=> numeral(value ?? 0).format("0,0.00") },
  { title: 'In', dataIndex: 'stableAmountIn', key: 'stableAmountIn', render: (value)=> numeral(value ?? 0).format("0,0.00") },
  { title: 'Out', dataIndex: 'stableAmountOut', key: 'stableAmountOut', render: (value)=> numeral(value ?? 0).format("0,0.00") },
  { title: 'Trend', dataIndex: 'trend', key: 'trend' },
];
  
export const Backtest = () => {
  const [reportData, setReportData] = useState<BacktestReportResponse[]>([]);
  const [form] = Form.useForm<FormModel>();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(true);
  const openNotificationWithIcon = (type: NotificationType, description: string) => {
    api[type]({
      message: 'Notification',
      description
    });
  };
  const tokenFirstValue = Form.useWatch("token0Id", form);
  const tokenSecondValue = Form.useWatch("token1Id", form);

  const expandedRowRender = (record: BacktestReportResponse) => {
     return <Table columns={subColumns} dataSource={record.positionList??[]} pagination={false} />;
  };

  const updateDataTable = async (values: FormModel) => {
    setLoading(true);
    const result = await RetroService.getApiRetro({
      datetimeInParam: values.dateInOutRange[0].toISOString(),
      datetimeOutParam: values.dateInOutRange[1].toISOString(),
      amountStable: values.amountStable,
      ratio: values.ratio,
      token0Id: values.token0Id,
      token1Id: values.token1Id,
      minChannelWidth: values.channel[0],
      maxChannelWidth: values.channel[1],
      exitTrigger: values.exitTrigger,
      switchDirection: values.switchDirection,
    });
    if (result && !result?.isSuccess) {
      openNotificationWithIcon('error', result?.errorText ?? "");
    }
    setLoading(false)
    setReportData(result.data!);
  }

  // Получить список токенов
  //const [tokens, setTokens] = useState<TokenDto[]>([]);
  const [token0Id, setTokensFirst] = useState<TokenDto[]>([]);
  const [token1Id, setTokensSecond] = useState<TokenDto[]>([]);
  useEffect(() => {
    form.setFieldsValue({
      amountStable: 1000,
      channel: [5, 30],
      exitTrigger: [0, 50],
      switchDirection: true,
      ratio: 20,
      dateInOutRange: [dayjs('2024-01-01 00:00:00'), dayjs('2024-01-05 00:00:00')],
    });
    RetroService.getApiRetroNettokens({
      // Hardcode Ethereum, т.к. нам нужны только метаданные
      netId: 1,
    }).then((result) => {
      //setTokens(result.data!);
      setTokensFirst(
        result.data?.filter((x) => x.isStableCoin === false) ?? []
      );
      setTokensSecond(
        result.data?.filter((x) => x.isStableCoin === true) ?? []
      );
      setTokenLoading(false);
    });
  },[]);

  return (
    <>
    {contextHolder}
    <div>Backtest</div>
    <Flex vertical>
    <Form form={form} layout="vertical" autoComplete="off" onFinish={updateDataTable}>
      <Form.Item name="token0Id" label="Token 1" rules={[{ required: true}]}>
        <Select options={token0Id.map((d) => ({
            value: d.id,
            label: d.symbol,
          }))} loading={tokenLoading}>
        </Select>
      </Form.Item>
      <Form.Item name="token1Id" label="Token 2" rules={[{ required: true }]}>
        <Select options={token1Id.map((d) => ({
            value: d.id,
            label: d.symbol,
          }))} loading={tokenLoading}>
        </Select>
      </Form.Item>
      <Form.Item label="RangePicker" name="dateInOutRange" rules={[{ required: true }]}>
        <RangePicker showTime />
      </Form.Item>
      <Form.Item label="Amount in stable" name="amountStable" rules={[{ required: true }]}>
        <InputNumber addonAfter="$" />
      </Form.Item>
      <Form.Item label="Ratio" name="ratio">
        <Slider min={1} max={99} />
      </Form.Item>
      <Form.Item label="Position exit %" name="exitTrigger">
        <Slider
          range
          step={1}
          defaultValue={[0, 50]}
        />
      </Form.Item>
      <Form.Item label="Channel widths % to iterate" name="channel">
        <Slider
          range
          min={1}
          max={99}
          step={1}
          defaultValue={[5, 50]}
        />
      </Form.Item>
      <Form.Item label="Switch direction" valuePropName="checked" name="switchDirection">
          <Switch />
      </Form.Item>
      <Form.Item>
        <Button type="primary"
            htmlType="submit"
            size="small"
            disabled={loading || !tokenFirstValue || !tokenSecondValue}
            style={{ width: 90 }}>Get report</Button>
    </Form.Item>
    </Form>
    </Flex>
    {reportData && <Table
          columns={mainColumns}
          loading={loading}
          rowKey={"channelWidth"}
          expandable={{expandedRowRender, defaultExpandedRowKeys:[] }}
          dataSource={reportData} />
        }
    </>
  );
};