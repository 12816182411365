import { GetProp, Flex, Table, TablePaginationConfig, TableProps, Typography, Segmented, TableColumnType, Space, Button, InputNumber, Select } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { DictionaryService, NetDto, PoolStatisticDto, PublicPoolStatisticService, SwapperResponse } from "shared/api";
import { round } from "shared/utils";
import numeral from "numeral";
import { SearchOutlined } from "@ant-design/icons";
import { ChartCell } from "feature/chartCell";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

type StatisticItem =
  {
    pair: string,
    poolName: string,
    poolHref: string,
    TVL: number,    
  } & PoolStatisticDto

interface Dictionaries {
  networks: NetDto[];
  swappers: SwapperResponse[];
  tokens: string[];
}

// Константы для специального значения, указывающего на отсутствие границы
const NO_BOUND = 0xFFFFFFFF; // Максимальное значение для беззнакового 32-битного числа

// Функция для кодирования минимума и максимума в одном числе
function encodeRange(min: number | null, max: number | null): bigint {
    const minValue = min !== null ? BigInt(min) : BigInt(NO_BOUND);
    const maxValue = max !== null ? BigInt(max) : BigInt(NO_BOUND);
    
    // Перемещаем минимальное значение в старшие 32 бита и добавляем максимальное значение
    return (minValue << BigInt(32)) | maxValue;
}

// Функция для декодирования минимума и максимума из одного числа
function decodeRange(encodedRange: bigint): { min: number | null, max: number | null } {
    const minValue = Number(encodedRange >> BigInt(32));
    const maxValue = Number(encodedRange & BigInt(0xFFFFFFFF));
    
    return {
        min: minValue === NO_BOUND ? null : minValue,
        max: maxValue === NO_BOUND ? null : maxValue
    };
}

const getExt = (netId: number) => {
  if ((netId > 0 && netId <= 6 && netId !== 5) || netId === 11 || netId === 13) return 'png'
  else if (netId === 7 || netId === 15) return 'jpg'
  return 'svg'
}

const getPoolUrl =()=>{
  //https://app.uniswap.org/add/v2/ETH/0xdAC17F958D2ee523a2206206994597C13D831ec7
  
}


const transformCalcPeriod = (value: string): string => {
  switch (value) {
    case 'Daily':
      return "24hours"
    case 'Weekly':
      return "7day"
    case 'Monthly':
      return "31day"
    default:
      return "365day"
  }
}

const calculateFees = (radingVolumeBothInUSD: number, feeTier: number) => {
  return round((radingVolumeBothInUSD) * (feeTier / 1000000), 1)
}

function multisplit(input: string, ...splits: string[]) {
  let _input = [input]
  while (splits.length) {
    let splitter = splits.shift()
    _input = _input.flatMap(sub => sub.split(splitter!))
  }
  return _input.map(el => el.trim())
}

const getColumns = (period: string, dict: Dictionaries, freeSearchProps: any): ColumnsType<StatisticItem> => {
  const daysCount = period === "24hours" ? 1 : (period === "7day" ? 7 : (period === "31day" ? 31 : 365))
  return [
    {
      title: 'Chain',
      dataIndex: 'netId',
      align: 'center',
      width: '30px',
      render: (netId) => <img width={20} height={20} src={`/chains/${netId}.${getExt(netId)}`} />,
      filters: dict.networks.map(x => ({ text: (<Flex gap={10}><img width={20} height={20} src={`/chains/${x.id}.${getExt(x.id!)}`} /><div>{x.name!}</div></Flex>), value: x.id! })),

      onFilter: (value, record) => record.netId === value,
    },
    {
      title: 'dApp',
      dataIndex: 'swapperId',
      align: 'center',
      width: '30px',
      render: (swapperId) => <img width={20} height={20} src={`/dex/${swapperId}.png`} />,
      filters: dict.swappers.map(x => ({ text: (<Flex gap={10}><img width={20} height={20} src={`/dex/${x.id}.png`} /><div>{x.name!}</div></Flex>), value: x.id! })),

      onFilter: (value, record) => record.swapperId === value,
    },
    {
      title: 'Assets',
      render: (obj) => <Typography.Link strong target="_blank" href={dict.networks !== undefined ? `${dict.networks.find(n => n.id === obj.netId)?.explorer!}/address/${obj.poolAddress}` : "/#"}>{`${obj.token0Symbol}-${obj.token1Symbol} ${obj.feeTier / 10000}%`}</Typography.Link>,
      width: '100%',
      ...freeSearchProps('Assets', ['token0Symbol', 'token1Symbol', 'feeTier'],
        (obj: any, record: any, index: number) => <Typography.Link strong target="_blank" href={dict.networks !== undefined ? `${dict.networks.find(n => n.id === obj.netId)?.explorer!}/address/${obj.poolAddress}` : "/#"}>{`${obj.token0Symbol}-${obj.token1Symbol} ${obj.feeTier / 10000}%`}</Typography.Link>)
    },
    {
      title: 'Trades',
      dataIndex: `tradeCount_${period}`,
      align: 'right',
      render: (value) => numeral(value ?? 0).format('0,0'),
      sorter: (a: any, b: any) => (a[`tradeCount_${period}`] ?? 0) - (b[`tradeCount_${period}`] ?? 0),
    },
    /*{
      title: 'VolumeSrc',
      dataIndex: `tradingVolumeBothInUSD_${period}`,
      render: (value) => round(value, 2),
      sorter: (a: any, b: any) => (a[`tradingVolumeBothInUSD_${period}`] ?? 0) - (b[`tradingVolumeBothInUSD_${period}`] ?? 0),
    },*/
    {
      title: 'Volume',
      dataIndex: `tradingVolumeBothInUSD_${period}`,
      align: 'right',
      render: (value) => numeral(value).format('0,0.00a'),
      sorter: (a: any, b: any) => (a[`tradingVolumeBothInUSD_${period}`] ?? 0) - (b[`tradingVolumeBothInUSD_${period}`] ?? 0),
    },
    {
      title: 'Fees',
      dataIndex: `collectedFeeInUSD_${period}`,
      align: 'right',
      render: (value) => numeral(value).format('0,0.00a'),
      sorter: (a: any, b: any) => (a[`collectedFeeInUSD_${period}`] ?? 0) - (b[`collectedFeeInUSD_${period}`] ?? 0),
    },

    {
      title: 'TVL',
      align: 'right',
      dataIndex: 'lockedVolumeBothInUsd',
      render: (val) => numeral(val).format('0,0.00a'),
      sorter: (a: any, b: any) => a.TVL! - b.TVL!,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {         
        const decoded = selectedKeys.length === 1? decodeRange(selectedKeys[0] as bigint):{min:null, max:null};
        console.log(decoded)   
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Flex vertical>
            <InputNumber min={1} precision={0} placeholder="Minimum" value={decoded.min}
                onChange={(value) => { setSelectedKeys([encodeRange(value, decoded.max)])}}
                style={{ width: "100%", marginBottom: 8, flexGrow: 1 }} />
              <InputNumber min={1} precision={0} placeholder="Maximum" value={decoded.max}
                onChange={(value) => { setSelectedKeys([encodeRange(decoded.min, value)]) }}
                style={{ width: "100%", marginBottom: 8, flexGrow: 1 }} />
            </Flex>          
            <Space>
              <Button
                type="primary"
                onClick={() => confirm({ closeDropdown: true })}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {  setSelectedKeys([]); clearFilters && clearFilters(); confirm({ closeDropdown: true }) }}//{() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
  
            </Space>
          </div>
        )
      },
      onFilter: (value, record)=> {
          if(!value) return true;
          const range = decodeRange(value as bigint)
          return record.TVL >=(range.min??0) && record.TVL<=(range.max??Number.MAX_SAFE_INTEGER)
      },
    },
    {
      title: 'APR',
      dataIndex: `apr_${period}`,
      align: 'right',
      render: (value) => `${round(value, 2)}%`,
      sorter: (a: any, b: any) => a[`apr_${period}`]! - b[`apr_${period}`]!,
    },
    {
      title: 'APR Chart',
      dataIndex: `aprLine_${period}`,
      align: 'center',
      render: (value) => <ChartCell points={value} />,
    },
  ];
}

type DataIndex = keyof StatisticItem;


export const Monitor = () => {
  const [dict, setDict] = useState<Dictionaries>({ networks: [], swappers: [], tokens: [] });
  const [data, setData] = useState<StatisticItem[]>();
  const [calcPeriod, setCalcPeriod] = useState<string>("Weekly")

  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [token0List, setToken0List] = useState<string[]>([]);
  const [token1List, setToken1List] = useState<string[]>([]);


  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setToken0List([]);
    setToken1List([]);
  };


  const getToken0List = () => token0List;

  const generateVariants = (arr: string[]): string[] => {
    debugger
    const arr1: string[] = arr.filter(x => x.startsWith('0:')).map(x => x.substring(2))
    const arr2: string[] = arr.filter(x => x.startsWith('1:')).map(x => x.substring(2))
    const result: string[] = []
    result.push(JSON.stringify(arr))
    if (arr1.length > 0 && arr2.length === 0) {
      arr1.forEach(item => {
        result.push(`${item}!`);
      });
    }
    else if (arr1.length === 0 && arr2.length > 0) {
      arr2.forEach(item => {
        result.push(`${item}!`);
      });
    }
    else {
      arr1.forEach(item1 => {
        arr2.forEach(item2 => {
          if (item1 !== item2) {
            result.push(`${item1}-${item2}`);
            result.push(`${item2}-${item1}`);
          }
        })
      })
    }
    return result;
  }

  const getColumnSearchProps = (title: string, dataIndexes: DataIndex[], render?: (value: any, record: StatisticItem, index: number) => React.ReactNode): TableColumnType<StatisticItem> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
      const selectedValues: string[] = selectedKeys.length >= 1 ? JSON.parse(selectedKeys[0].toString()) : []
      const token0List = selectedValues.filter(x => x.startsWith('0:')).map(x => x.substring(2))
      const token1List = selectedValues.filter(x => x.startsWith('1:')).map(x => x.substring(2))
      console.log('selectedKeys', selectedKeys, token0List, token1List, dict);
      return (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Flex vertical>
            <Select mode="multiple" placeholder={'First token'} value={token0List} options={dict.tokens.map(x => ({ value: x, label: x }))}
              onChange={(values) => { setToken0List(values); setSelectedKeys(generateVariants([...selectedValues.filter((x: any) => x.startsWith('1:')), ...values.map((v: string) => `0:${v}`)])) }}
              style={{ marginBottom: 8, flexGrow: 1 }} />
            <Select mode="multiple" placeholder={'Second token'} value={token1List} options={dict.tokens.map(x => ({ value: x, label: x }))}
              onChange={(values) => { setToken1List(values); setSelectedKeys(generateVariants([...selectedValues.filter((x: any) => x.startsWith('0:')), ...values.map((v: string) => `1:${v}`)])) }}
              style={{ marginBottom: 8, flexGrow: 1 }} />
          </Flex>          
          <Space>
            <Button
              type="primary"
              onClick={() => confirm({ closeDropdown: true })}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => { debugger; setSelectedKeys([]); clearFilters && handleReset(clearFilters); confirm({ closeDropdown: true }) }}//{() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>

          </Space>
        </div>
      )
    },
    onFilter: (value, record: StatisticItem) => {
      const strVal = value as string
      if (strVal.startsWith('[')) return false;
      if (strVal.endsWith('!')) {
        const sv = strVal.substring(0, strVal.length - 1);
        return record.token0Symbol === sv || record.token1Symbol === sv;
      }
      return record.pair === strVal;
    },
    render: (value: any, record: StatisticItem, index: number) =>
      render ? render(value, record, index) : value,
  });

  const [columns, setColumns] = useState<ColumnsType<StatisticItem>>(getColumns(transformCalcPeriod(calcPeriod), dict, getColumnSearchProps));
  useEffect(() => {
    if (dict) {
      setColumns(getColumns(transformCalcPeriod(calcPeriod), dict, getColumnSearchProps))
    }
  }, [calcPeriod, dict])

  const clearData = (data: PoolStatisticDto[]) => {
    const result = data.map(x => {
      let tvl = x.lockedVolumeBothInUsd??0;
      tvl = tvl < 10 ? 0 : tvl;
      return ({
        ...x,
        pair: `${x.token0Symbol}-${x.token1Symbol}`,
        poolName: `${x.token0Symbol}-${x.token1Symbol} ${x.feeTier! / 10000}%`,
        poolHref: dict.networks !== undefined ? `${dict.networks.find(n => n.id === x.netId)?.explorer!}/address/${x.poolAddress}` : "/#",
        TVL: tvl
        /*
        APR_24hours: tvl > 10 ? round((calculateFees(x.tradingVolumeBothInUSD_24hours!, x.feeTier!) * 100.0 / tvl) / 1.0 * 1, 5) : 0,
        APR_7day: tvl > 10 ? round((calculateFees(x.tradingVolumeBothInUSD_7day!, x.feeTier!) * 100.0 / tvl) / 7.0 * 1, 5) : 0,
        APR_31day: tvl > 10 ? round((calculateFees(x.tradingVolumeBothInUSD_31day!, x.feeTier!) * 100.0 / tvl) / 31.0 * 1, 5) : 0,
        APR_365day: tvl > 10 ? round((calculateFees(x.tradingVolumeBothInUSD_365day!, x.feeTier!) * 100.0 / tvl) / 365.0 * 1, 5) : 0*/
      })
    })
    return result
  }

  // Функция для получения отсортированного списка уникальных токенов
  const getUniqueSortedTokens = (arr: PoolStatisticDto[]): string[] => {
    // Создаем множество для хранения уникальных символов токенов
    const tokenSet = new Set<string>();

    // Проходим по массиву объектов и добавляем символы токенов в множество
    arr.forEach(item => {
      tokenSet.add(item.token0Symbol!);
      tokenSet.add(item.token1Symbol!);
    });

    // Преобразуем множество в массив и сортируем его
    return Array.from(tokenSet).sort();
  }

  const fetchData = async () => {
    setLoading(true);
    const networkRequest = DictionaryService.getApiDictionaryNetworks({});
    const swappersRequest = DictionaryService.getApiDictionarySwappers({});
    const result = await Promise.all([networkRequest, swappersRequest])
    const dictionaries = { networks: result[0].data ?? [], swappers: result[1].data ?? [], tokens: [] }
    setDict(dictionaries);
    PublicPoolStatisticService.getApiPublicpoolstatistic(
      {
        page: tableParams.pagination?.current ?? 1,
        size: 10000,//tableParams.pagination?.pageSize??15,
        orderBy: tableParams.sortField ? [tableParams.sortField] : []
      })
      .then((results) => {
        const data = results.data?.queryable ?? []
        setDict({ ...dictionaries, tokens: getUniqueSortedTokens(data) })
        setData(clearData(data));
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: results.data?.rowCount ?? 0,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      });
  };


  useEffect(() => {
    fetchData();
  }, []);

  const onChange: TableProps<StatisticItem>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  return (
    <Flex vertical>
      <Segmented<string>
        options={['Daily', 'Weekly', 'Monthly', 'Yearly']}
        value={calcPeriod}
        onChange={(value) => {
          setCalcPeriod(value)
        }}
      />
      <Table
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id!}
        //pagination={tableParams.pagination}
        loading={loading}
        onChange={onChange}
      //onChange={handleTableChange}
      />
    </Flex>
  );
};