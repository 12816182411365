/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BacktestReportResponseIEnumerableResponseData } from '../models/BacktestReportResponseIEnumerableResponseData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { TokenDtoIEnumerableResponseData } from '../models/TokenDtoIEnumerableResponseData';
export class RetroService {
    /**
     * @returns BacktestReportResponseIEnumerableResponseData Success
     * @throws ApiError
     */
    public static getApiRetro({
        datetimeInParam,
        datetimeOutParam,
        amountStable,
        ratio,
        token0Id,
        token1Id,
        minChannelWidth,
        maxChannelWidth,
        exitTrigger,
        switchDirection
    }: {
        datetimeInParam?: string,
        datetimeOutParam?: string,
        amountStable?: number,
        ratio?: number,
        token0Id?: number,
        token1Id?: number,
        minChannelWidth?: number,
        maxChannelWidth?: number,
        exitTrigger?: Array<number>,
        switchDirection?: boolean
    }): CancelablePromise<BacktestReportResponseIEnumerableResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/retro',
            query: {
                'datetimeInParam': datetimeInParam,
                'datetimeOutParam': datetimeOutParam,
                'amountStable': amountStable,
                'ratio': ratio,
                'token0Id': token0Id,
                'token1Id': token1Id,
                'minChannelWidth': minChannelWidth,
                'maxChannelWidth': maxChannelWidth,
                'exitTrigger': exitTrigger,
                'switchDirection': switchDirection
            },
        });
    }
    /**
     * @returns TokenDtoIEnumerableResponseData Success
     * @throws ApiError
     */
    public static getApiRetroNettokens({
        netId,
    }: {
        netId: number,
    }): CancelablePromise<TokenDtoIEnumerableResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/retro/nettokens',
            query: {
                'netId': netId,
            },
        });
    }
}
