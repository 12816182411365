/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * CEX-биржа
 */
export enum ExchangeType {
    BINANCE = 'Binance',
    BY_BIT = 'ByBit',
}
