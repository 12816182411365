import React, { useEffect } from "react";
import { provider, useExpand } from "shared";
import MobileRoutingPages from "pages/mobile";
import SiteRoutingPages from "pages/site";
import PullToRefreshComponent from "feature/pullToRefresh";

const App = () => {
  const [, expand] = useExpand();
  useEffect(() => {
    expand();
  }, []);
  return (
    <PullToRefreshComponent>
      <div>
        <provider.WebAppProvider options={{ smoothButtonsTransition: true }}>
          <provider.ThemeProvider>
            <provider.TokenProvider
              otherMethodChildren={<MobileRoutingPages />}
              qrCodeChildren={<SiteRoutingPages />}
            >
            </provider.TokenProvider>
          </provider.ThemeProvider>
        </provider.WebAppProvider>
      </div>
    </PullToRefreshComponent>
  );
};

export default App;
