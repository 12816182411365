import { Button, Flex, Typography, theme } from "antd";
import {
  AlertOutlined,
  WalletOutlined,
  SlidersOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import React, { MouseEventHandler, ReactNode } from "react";
import { RoutePaths } from "shared";

interface ButtonTextProps {
  icon?: ReactNode;
  onClick?: MouseEventHandler | undefined;
  text?: string;
}

const ButtonText = ({ icon, onClick, text }: ButtonTextProps) => {
  const { token } = theme.useToken();
  return (
    <Flex align="center" vertical>
      <Button
        type="primary"
        shape="circle"
        className="mb-2"
        icon={icon}
        onClick={onClick}
      />
      <Typography.Text style={{ color: token.colorPrimary }}>
        {text}
      </Typography.Text>
    </Flex>
  );
};

export const Routing = () => {
  const navigate = useNavigate();

  return (
    <Flex align="large" justify="space-between" className="px-3 pt-7">
      <ButtonText
        icon={<AlertOutlined />}
        onClick={() => navigate(RoutePaths.Exchanges.Path)}
        text="Доступы"
      />
      <ButtonText
        icon={<WalletOutlined />}
        onClick={() => navigate(RoutePaths.Wallets.Path)}
        text="Кошельки"
      />
      <ButtonText
        icon={<SlidersOutlined />}
        onClick={() => navigate(RoutePaths.Scenarios.Path)}
        text="Стратегии"
      />
      <ButtonText
        icon={<DollarOutlined />}
        onClick={() => navigate(RoutePaths.Earnings.Path)}
        text="Заработок"
      />
    </Flex>
  );
};
