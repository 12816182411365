import { Column } from "@ant-design/charts";
import { Carousel, Flex, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { Dashboard, EarningsService } from "shared/api";

export type DashboardWidgetProps = {
  height?: string | number
  positionId?: number;
  sessionId?: number;
  scenarioId?: number;
}

export const DashboardWidget = (props: DashboardWidgetProps) => {

  const [loading, setLoading] = useState(true);

  const [dashbordData, setDashboardData] = useState<Dashboard>();
  useEffect(() => {
    EarningsService.getApiEarningsDashboard(
      {
        positionId: props.positionId,
        sessionId: props.sessionId,
        scenarioId: props.scenarioId
      }
    ).then((result) => {
      setDashboardData(result.data);
      setLoading(false);

    });
  }, []);

  const getConfig = (data: any[]) => {return {
    data,  
    height:100,  
    xField: 'date',
    yField: 'value',
    style: {      
      fill: ({ value } : any) => {
        if (value >= 0) {
          return '#32a852';
        }
        return '#a83232';
      },      
      radiusTopLeft: 5,
      radiusTopRight: 5,
    },
    scale: {
      y: {
        domainMax: Math.max(...data.map(item => item.value)),
        domainMin: Math.min(...data.map(item => item.value)),
      },
    },
    axis: {
      x: {        
        labelFormatter: (value: any) =>  value && value.length>=18 ? new Date(value).getDate() : value,
        label: {
          style: {
            fill: '#FFFFFF', // цвет текста на оси X
          },
        },
      },
    },
    label:{
      style: {
        opacity:0,
        fill: '#FFFFFF', // цвет текста
      },
    },
    theme: "classicDark",
    legend: false,
  }}
  ;
  return (
    <Flex vertical style={{width:"100%", height:"110px"}} >
      {loading ?
        <Spin spinning={loading} /> :
        <Carousel>
          <Flex vertical>
            <Typography.Text type={dashbordData?.weekProfit && dashbordData?.weekProfit >= 0 ? "success" : "danger"}>Профит за неделю: {dashbordData?.weekProfit}$</Typography.Text>
            <Column {...getConfig(dashbordData?.weekProfitItems as any[])} />
          </Flex>
          <Flex vertical>
            <Typography.Text type={dashbordData?.monthProfit && dashbordData?.monthProfit >= 0 ? "success" : "danger"}>Профит за месяц: {dashbordData?.monthProfit}$</Typography.Text>
            <Column  {...getConfig(dashbordData?.monthProfitItems as any[])} />
            </Flex>

            <Flex vertical>
            <Typography.Text type={dashbordData?.yearProfit && dashbordData?.yearProfit >= 0 ? "success" : "danger"}>Профит за год: {dashbordData?.yearProfit}$</Typography.Text>
            <Column  {...getConfig(dashbordData?.yearProfitItems as any[])} />
          </Flex>
        </Carousel>
      }
    </Flex>
  );
};
