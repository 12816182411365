import React, { useContext, useState } from 'react';
import {
  DesktopOutlined,
  FireOutlined,
  PieChartOutlined,
  PoweroffOutlined,
  SettingOutlined,
  SlidersOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Avatar, Breadcrumb, Button, Flex, Layout, Menu, Popover, Typography, theme } from 'antd';
import { TokenContext, useTokenContext } from 'shared/contexts';
import { RoutePaths } from "shared";
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { Monitor } from "./monitor";
import { Statistic } from "./statistic";
import { Backtest } from './backtest';

const { Header, Content, Footer, Sider } = Layout;
const { Text, Link } = Typography;

type MenuItem = Required<MenuProps>['items'][number];


const SiteRoutingPages: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const tokenData = useContext(TokenContext);
  const { logout } = useTokenContext();
  const navigate = useNavigate();
  const location = useLocation();

  function getItem(
    label: React.ReactNode,
    key: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      onClick: () => navigate(key)
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem('Analytics', RoutePaths.Monitor.Path, <DesktopOutlined />),
    getItem('Statistics', RoutePaths.Statistic.Path, <PieChartOutlined />),   
    getItem('My positions', RoutePaths.MyPositions.Path, <FireOutlined />),     
    getItem('Backtest', RoutePaths.Backtest.Path, <SettingOutlined />),
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={230} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          defaultSelectedKeys={[RoutePaths.Monitor.Path]}
          selectedKeys={[location.pathname]}
          mode="inline"
          items={items} />
      </Sider>
      <Layout>
        {/*<Header  style={{ padding: 0, background: colorBgContainer }} />*/}
        <Content style={{ margin: '0 16px' }}>
          <Flex justify='space-between' align='center'>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>User</Breadcrumb.Item>
              <Breadcrumb.Item>Bill</Breadcrumb.Item>
            </Breadcrumb>
            <Flex align='center' gap={10}>
              <Text type="secondary">{tokenData?.userName}</Text>
              <Popover content={<Flex vertical><Button
          type="primary"
          icon={<PoweroffOutlined />}
          onClick={() => logout()}
        >Logout</Button></Flex>} trigger="click">
              <Avatar style={{ padding: 4, backgroundColor: '#87d068' }} icon={<UserOutlined />} />
              </Popover>
            </Flex>
          </Flex>

          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate to={RoutePaths.Monitor.Path} replace />} />
              <Route path={RoutePaths.Statistic.Path} element={<Statistic />} />
              <Route
                path={RoutePaths.Monitor.Path}
                element={<Monitor />}
              />
              <Route
                path={RoutePaths.Backtest.Path}
                element={<Backtest />}
              />
            </Routes>
          </div>
        </Content>
        {/*<Footer style={{ textAlign: 'center' }}>
          Hedger ©{new Date().getFullYear()} Created by WhiteFrame
          </Footer>*/}
      </Layout>
    </Layout>
  );
};

export default SiteRoutingPages;