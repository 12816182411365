/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PoolStatisticDtoPagedResultResponseData } from '../models/PoolStatisticDtoPagedResultResponseData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PublicPoolStatisticService {
    /**
     * Получить записи о пулах ликвидности
     * @returns PoolStatisticDtoPagedResultResponseData Success
     * @throws ApiError
     */
    public static getApiPublicpoolstatistic({
        page,
        size,
        orderBy,
        filter,
        searches,
    }: {
        /**
         * Номер страницы, начиная с 1
         */
        page?: number,
        /**
         * Размер страницы
         */
        size?: number,
        orderBy?: Array<string>,
        filter?: Array<string>,
        searches?: Array<string>,
    }): CancelablePromise<PoolStatisticDtoPagedResultResponseData> {
        return __request({...OpenAPI, BASE:"https://back.safepool.fi"}, {
            method: 'GET',
            url: '/api/publicpoolstatistic',
            query: {
                'Page': page,
                'Size': size,
                'OrderBy': orderBy,
                'Filter': filter,
                'Searches': searches,
            },
        });
    }
}
