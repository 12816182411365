import { useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Flex, Form,  InputNumber, Space, Spin, Tag, Typography } from "antd";
import { MobileSelect, SubmitButton } from "feature";
import { OptionFormModel, Point, SecondFormModel } from "../../../model";
import { useEffect, useState } from "react";
import {
    ExchangeType,
    OptionSliceService,
    ScenarioService,
    ScenarioSimpleDto,    
    SliceItem,
    StrategyType,
    round,
    useShowPopup,
} from "shared";
import { Line } from "@ant-design/charts";
import { calculate_best } from "pages/mobile/scenarios/functions";


type CalculationResult = {
    expirationDate: string | null | undefined;
    item: SliceItem;
    hedgeUnits: number;
    maxPnl: number;
    kpd: number;
    points: Point[];
    rank?: number;
    daysToExpiration: number
}

export const OptionSettingsPart = () => {
    const [form] = Form.useForm<OptionFormModel>();

    const location = useLocation();
    const state = location.state as SecondFormModel;

    interface ILoading {
        expiration: boolean;
        options: boolean;
    }
    const [loading, setLoading] = useState<ILoading>({
        expiration: false,
        options: false,
    });

    const [topList, setTopList] = useState<CalculationResult[]>([]);
    const [chartData, setChartData] = useState<any>({});
    const feePerDay = Form.useWatch("feePerDay", form);
    const option = Form.useWatch("option", form);

    useEffect(() => {
        form.setFieldValue("feePerDay", 0.1)
    }, []);


    useEffect(() => {
        setLoading({ ...loading, options: true });
        OptionSliceService.getApiOptionsliceSliceAll({
            cexCode: state.exchange.type === ExchangeType.BINANCE ? 1 : 2,
            asset: state.tokenFirst.realSymbol ?? state.tokenFirst.symbol!
        }).then((result) => {
            const data = result.data!;
            const top10 = calculate_best(state as any, state.pool.currentPrice!, data);
            setTopList(top10);            
            form.setFieldsValue({ option: 1, optionAmount: round(top10[0].hedgeUnits, 1) })
            setLoading({ ...loading, options: false });
        });
    }, []);

    useEffect(() => {
        if (feePerDay>=0 && topList && option) {
            const feePerDayValue = state.amount * (feePerDay / 100)
            setChartData({
                data: [...topList[option - 1].points.map(x => ({ price: x.price, pnl: x.pnl + topList[option - 1].daysToExpiration * feePerDayValue }))],
                xField: 'price',
                yField: 'pnl',
                legend: { size: false },
                theme: "classicDark",
            })
        }
    }, [feePerDay, topList, option])

    

    const showPopUp = useShowPopup();
    const navigate = useNavigate();
    const onFinish = async (values: any) => {
        const request: ScenarioSimpleDto = {
            netId: state.network.id!,
            swapperId: state.swapper.id!,
            feeTier: state.pool.feeTier!,
            poolAddress: state.pool.id!,
            token0Id: state.tokenFirst.id!,
            token1Id: state.tokenSecond.id!,
            userExchangeId: state.exchange.id!,
            userWalletId: state.wallet.id,
            amount: state.amount,
            strategyType: state.strategyType as StrategyType,
            name: state.name,
            isReversed: state.pool.reversed ?? false,
            strategy: {
                ...state.strategy,
                Option: `${state.tokenFirst.realSymbol ?? state.tokenFirst.symbol!}-${topList[option-1].expirationDate!.replace(/-/g, '').substring(2)}-${topList[option-1].item.strikePrice}-P`,
                OptionAmount: values.optionAmount
            }
        };
        delete request.strategy.name
        delete request.strategy.amount

        try {
            const response = await ScenarioService.postApiScenario({
                requestBody: request,
            });

            if (response && !response?.isSuccess) {
                return showPopUp({
                    title: "Ошибка",
                    message: response?.errorText ?? "",
                });
            }

            navigate(-3);
        } catch (error: any) {
            if (error) {
                return showPopUp({ title: "Ошибка", message: error.message });
            }
        }
    };

    /*
        const calculate = (slice: SliceDto): number => {
            const currentPrice = round(state.pool.currentPrice!, 2);
            const priceRange = currentPrice * (state.strategy.ChannelWidthPercent / 100);
            const priceHigh = round(currentPrice + priceRange * ((100 - state.strategy.ChannelEntryOffset) / 100), 2);
            const priceLow = round(currentPrice - priceRange * ((state.strategy.ChannelEntryOffset) / 100), 2);
            const calculations = calculate_optimum(state.amount, currentPrice, feePerDay, priceLow, priceHigh, slice)!
            return calculations;
        }
    */
    const onOptionSelected = (rank: number) => {
    }

    type SizeType = "small" | "middle" | "large" | undefined;
    const props = {
        size: "large" as SizeType,
        type: "number",
        className: "w-full",
        suffix: <>{loading.expiration && <LoadingOutlined style={{ fontSize: 12 }} spin />}</>,
    };

    return (
        <>
            <Alert
                type="info"
                message={
                    <Flex gap={'small'} vertical align="center">
                        <Spin spinning={loading.options} size="small">

                            <Line width={320} height={100} autoFit={true} {...chartData} />
                        </Spin>
                    </Flex>}
                className="mb-3"
            />
            <Typography.Title level={5} style={{ marginTop: 0 }}>
                Текущая цена: {round(state.pool.currentPrice!, 2)}
            </Typography.Title>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
            >
                <Form.Item name="option" label="Опционы" rules={[{ required: true }]}>
                    <MobileSelect
                        loading={loading.options}
                        disabled={loading.options}
                        options={topList.map((d) => ({
                            value: d.rank!, //`${state.tokenFirst.realSymbol ?? state.tokenFirst.symbol!}-${expirationValue.replace('-', '')}- ${d.strikePrice}-P`,
                            display: `PUT ${d.expirationDate} ${d.item.strikePrice} ${d.item.highestBidPrice}`,
                            label: <Flex style={{ flexGrow: 1 }} justify="space-between" align="center">
                                <Space> <Typography.Text type="danger">PUT</Typography.Text>
                                    <Typography.Text strong>{d.item.strikePrice} </Typography.Text>
                                </Space>
                                <Tag color=" rgb(147 57 57)">{d.expirationDate}</Tag>
                                <Typography.Text strong type="success">{d.item.highestBidPrice}</Typography.Text></Flex>,
                        }))}
                        onChange={onOptionSelected}
                        size="large"
                    />
                </Form.Item>
                <Form.Item name="feePerDay" label="Дневной APR %" rules={[{ required: true }]}>
                    <InputNumber min={0} max={10} step={0.1} {...props} />
                </Form.Item>
                <Form.Item name="optionAmount" label="Количество units" rules={[{ required: true }]}>
                    <InputNumber readOnly {...props} />
                </Form.Item>
                <Form.Item>
                    <SubmitButton form={form} text="Сохранить" isMain={true} />
                </Form.Item>
            </Form>
        </>
    );
};
