const getExt = (netId: number) => {
    if ((netId > 0 && netId <= 6 && netId !== 5) || netId === 11 || netId === 13) return 'png'
    else if (netId === 7 || netId === 15) return 'jpg'
    return 'svg'
  }

  export type IconProps = {
    objectId?: number;
  } & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

  export const ChainIcon = (props:IconProps) =>
  {
    return <img width={20} height={20} {...props} src={`/chains/${props.objectId}.${props.objectId && getExt(props.objectId)}`} />
  }
  export const SwapperIcon = (props:IconProps) =>
  {
    return <img width={20} height={20} {...props} src={`/dex/${props.objectId}.png`} />
  }