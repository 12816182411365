import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Checkbox, Form, InputNumber, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { MobileSelect, SubmitButton } from "feature";
import {
  DictionaryService,
  ExchangeService,
  LightExchangeResponse,
  NetDto,
  PoolAmount,
  PositionInfo,
  PositionService,
  SwapperPoolItem,
  SwapperResponse,
  TokenDto,
  WalletService,
  useShowPopup,
} from "shared";
import { IResponseContainer, WalletModel } from "../../../positions/model";
import { SizeType } from "antd/es/config-provider/SizeContext";

export const PositionEdit = () => {
  const [form] = Form.useForm<PositionInfo>();
  const { id } = useParams();
  const location = useLocation();
  const state = location.state as PositionInfo;
  const nftCreated = (state.nftId ?? 0) > 0;

  // Получить список обменников
  const [swapper, setSwapper] = useState<SwapperResponse[] | null>(null);
  const [networks, setNetworks] = useState<NetDto[]>([]);
  const [tokensFirst, setTokensFirst] = useState<TokenDto[]>([]);
  const [tokensSecond, setTokensSecond] = useState<TokenDto[]>([]);
  const [wallets, setWallets] = useState<WalletModel[]>([]);
  const [exchange, setExchange] = useState<LightExchangeResponse[]>([]);


  const swapperValue = Form.useWatch("swapperId", form);
  const networkValue = Form.useWatch("netId", form);

  useEffect(() => {
    const swapperRequest = DictionaryService.getApiDictionarySwappers({}).then((result) => {
      setSwapper(result.data!);
    });
    const netsRequest = DictionaryService.getApiDictionaryNetworks({ swapperId: state.swapperId, }).then((result) => {
      setNetworks(result.data!);
    });
    const tokensRequest = DictionaryService.getApiDictionaryTokens({ netId: state.netId, swapperId:state.swapperId }).then((result) => {
      setTokensFirst(
        result.data?.filter((x) => x.isStableCoin === false) ?? []
      );
      setTokensSecond(
        result.data?.filter((x) => x.isStableCoin === true) ?? []
      );
    });
    const poolsRequest = DictionaryService.getApiDictionaryPools({
      netId: state.netId,
      swapperId: state.swapperId,
      token0Id: state.token0Id,
      token1Id: state.token1Id,
    }).then((result) => {
      setPools(result.data!);
    });
    const walletsRequest = WalletService.getApiWallet().then(
      (result: IResponseContainer<WalletModel[]>) => {
        setWallets(result?.data ?? []);
      }
    );
    const exchangesRequest = ExchangeService.getApiExchange().then((result) => {
      setExchange(result?.data!);
    });
    Promise.all([swapperRequest, netsRequest, tokensRequest, poolsRequest, walletsRequest, exchangesRequest]).then((results) => {
      form.setFieldsValue({ ...state as any });
      setTimeout(() => { setLoading({ ...loading, all: false }) }, 1000);
    })
  }, []);

  // Получить список сетей 
  useEffect(() => {
    if (!loading.all && swapperValue) {

      setLoading({ ...loading, network: true });
      DictionaryService.getApiDictionaryNetworks({
        swapperId: swapperValue,
      }).then((result) => {
        setNetworks(result.data!);
        setLoading({ ...loading, network: false });
        setTokensFirst([]);
        setTokensSecond([]);
        setPools([]);
        form.setFieldsValue({
          netId: undefined,
          token0Id: undefined,
          token1Id: undefined,
          feeTier: undefined,
        });
      });
    }
  }, [swapperValue]);

  // Получить список токенов

  useEffect(() => {
    if (!loading.all && networkValue && swapperValue) {
      setLoading({ ...loading, token: true });
      DictionaryService.getApiDictionaryTokens({
        netId: networkValue,
        swapperId: swapperValue
      }).then((result) => {
        setTokensFirst(
          result.data?.filter((x) => x.isStableCoin === false) ?? []
        );
        setTokensSecond(
          result.data?.filter((x) => x.isStableCoin === true) ?? []
        );
        setLoading({ ...loading, token: false });

        setPools([]);
        form.setFieldsValue({
          token0Id: undefined,
          token1Id: undefined,
          feeTier: undefined,
        });
      });
    }
  }, [networkValue, swapperValue]);

  // Получить список пулов
  const [pools, setPools] = useState<SwapperPoolItem[]>([]);
  const tokenFirstValue = Form.useWatch("token0Id", form);
  const tokenSecondValue = Form.useWatch("token1Id", form);
  useEffect(() => {
    if (!loading.all && tokenFirstValue && tokenSecondValue) {
      setLoading({ ...loading, pool: true });
      DictionaryService.getApiDictionaryPools({
        netId: networkValue!,
        swapperId: swapperValue!,
        token0Id: tokenFirstValue,
        token1Id: tokenSecondValue,
      }).then((result) => {
        setPools(result.data!);
        setLoading({ ...loading, pool: false });
        form.setFieldsValue({
          feeTier: undefined,
        });
      });
    }
  }, [tokenFirstValue, tokenSecondValue]);

  const showPopUp = useShowPopup();
  const navigate = useNavigate();
  const onFinish = async (value: PositionInfo) => {
    try {
      const selectedPool = pools.find(p=>p.feeTier === value.feeTier);
      const response = await PositionService.putApiPosition({
        requestBody: {...value, id: Number.parseInt(id!), poolAddress: selectedPool!.id!},
      });

      if (response && !response?.isSuccess) {
        return showPopUp({
          title: "Ошибка",
          message: response?.errorText ?? "",
        });
      }

      navigate(-1);
    } catch (error: any) {
      if (error) {
        return showPopUp({ title: "Ошибка", message: error.message });
      }
    }
  };

  interface ILoading {
    all: boolean;
    swapper: boolean;
    network: boolean;
    token: boolean;
    pool: boolean;
    wallet: boolean;
    exchange: boolean;
  }
  const [loading, setLoading] = useState<ILoading>({
    all: true,
    swapper: false,
    network: false,
    token: false,
    pool: false,
    wallet: false,
    exchange: false,
  });

  const props = {
    min: 0,
    size: "large" as SizeType,
    type: "number",
    className: "w-full",
    disabled: loading.all,
  };

  return (
    <Spin tip="Загрузка..." spinning={loading.all}>
      <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
        <Form.Item name="swapperId" label="Обменник" rules={[{ required: true }]}>
          <MobileSelect
            loading={loading.swapper}
            disabled={nftCreated}
            options={swapper?.map((d) => ({
              value: d.id,
              label: d.name,
            }))}
            size="large"
          />
        </Form.Item>
        <Form.Item name="netId" label="Сеть" rules={[{ required: true }]}>
          <MobileSelect
            loading={loading.network}
            disabled={nftCreated || !swapperValue}
            options={networks?.map((d) => ({
              value: d.id,
              label: d.name,
            }))}
            size="large"
          />
        </Form.Item>
        <Form.Item name="token0Id" label="Токен 1" rules={[{ required: true }]}>
          <MobileSelect
            loading={loading.token}
            disabled={nftCreated || !networkValue}
            options={tokensFirst.map((d) => ({
              value: d.id,
              label: d.symbol,
            }))}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="token1Id"
          label="Токен 2"
          rules={[{ required: true }]}
        >
          <MobileSelect
            loading={loading.token}
            disabled={nftCreated || !networkValue}
            options={tokensSecond.map((d) => ({
              value: d.id,
              label: d.symbol,
            }))}
            size="large"
          />
        </Form.Item>

        <Form.Item name="feeTier" label="Пул" rules={[{ required: true }]}>
          <MobileSelect
            loading={loading.pool}
            disabled={nftCreated || !tokenFirstValue || !tokenSecondValue}
            options={pools?.map((d) => ({
              value: d.feeTier,
              label: d.name,
            }))}
            size="large"
          />
        </Form.Item>

        <Form.Item name="userWalletId" label="Кошель" rules={[{ required: true }]}>
          <MobileSelect
            disabled={nftCreated}
            options={wallets.map((d) => ({
              value: d.id,
              label: d.name || d.address
            }))}
            size="large"
          />
        </Form.Item>
        <Form.Item name="userExchangeId" label="Доступ" rules={[{ required: true }]}>
          <MobileSelect
            disabled={nftCreated}
            options={exchange.map((d) => ({
              value: d.id,
              label: d.name,
            }))}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="hedgeInputPricePercent"
          label="Процент от цены канала для входа"
          rules={[{ required: true }]}
        >
          <InputNumber
            min={5}
            max={50}
            suffix="%"
            size="large"
            type="number"
            className="w-full"
          />
        </Form.Item>
        <Form.Item
          name="slippagePercent"
          label="Проскальзование %"
          rules={[{ required: true }]}
        >
          <InputNumber
            min={0}
            max={100}
            suffix="%"
            size="large"
            type="number"
            className="w-full"
          />
        </Form.Item>
        <Form.Item name="useLongHedge" valuePropName="checked">
          <Checkbox  >Использовать Long-хеджирование</Checkbox>
        </Form.Item>
        <Form.Item name="hedgeOnlyEdge" valuePropName="checked">
          <Checkbox  >Хеджировать только по краям (Вход по маркету)</Checkbox>
        </Form.Item>
        <Form.Item
          name="lowerPrice"
          label="Низкая цена"
          rules={[{ required: true }]}
        >
          <InputNumber {...props} disabled={nftCreated} />
        </Form.Item>
        <Form.Item
          name="upperPrice"
          label="Высокая цена"
          rules={[{ required: true }]}
        >
          <InputNumber {...props} disabled={nftCreated} />
        </Form.Item>
        <Form.Item
          name="amount"
          label={"Сумма вклада"}
          rules={[{ required: true }]}
        >
          <InputNumber {...props} />
        </Form.Item>
        <Form.Item name="leverage" label={"Плечо"} rules={[{ required: true }]}>
          <InputNumber {...props} />
        </Form.Item>
        <Form.Item
          name="stopLossPercent"
          label={"Стоп-лосс в процентах"}
          rules={[{ required: true }]}
        >
          <InputNumber {...props} step={0.1} />
        </Form.Item>
        <SubmitButton form={form} text="Сохранить" isMain={true} />
      </Form>
    </Spin>
  );

};
