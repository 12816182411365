import { createContext, useContext } from "react";

export interface ITokenModel {
  token: string;
  refreshToken: string;
  expires: string;
  expiresRefresh: string;
  role: string;
  userId: number;  
  userName?: string | null;
}
export interface ITokenContext extends ITokenModel {
  logout: () => void;
}

export const TokenContext = createContext<ITokenContext | null>(null);

export const useTokenContext = (): ITokenContext => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error("TokenContext must be used within a TokenProvider");
  }
  return context;
};
