/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PositionDto } from '../models/PositionDto';
import type { PositionInfoResponseData } from '../models/PositionInfoResponseData';
import type { PositionResponseIEnumerableResponseData } from '../models/PositionResponseIEnumerableResponseData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PositionService {
    /**
     * @returns PositionResponseIEnumerableResponseData Success
     * @throws ApiError
     */
    public static getApiPosition({
        scenarioId,
        sessionId,
        activeOnly,
    }: {
        scenarioId?: number,
        sessionId?: number,
        activeOnly?: boolean,
    }): CancelablePromise<PositionResponseIEnumerableResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/position',
            query: {
                'scenarioId': scenarioId,
                'sessionId': sessionId,
                'activeOnly': activeOnly,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiPosition({
        requestBody,
    }: {
        requestBody?: PositionDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/position',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static putApiPosition({
        requestBody,
    }: {
        requestBody?: PositionDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/position',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiPosition({
        positionId,
    }: {
        positionId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/position',
            query: {
                'positionId': positionId,
            },
        });
    }
    /**
     * @returns PositionInfoResponseData Success
     * @throws ApiError
     */
    public static getApiPosition1({
        positionId,
    }: {
        positionId: number,
    }): CancelablePromise<PositionInfoResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/position/{positionId}',
            path: {
                'positionId': positionId,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiPositionOpen({
        positionId,
    }: {
        positionId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/position/open/{positionId}',
            path: {
                'positionId': positionId,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiPositionError({
        positionId,
    }: {
        positionId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/position/error/{positionId}',
            path: {
                'positionId': positionId,
            },
        });
    }
}
