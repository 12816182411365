import { Flex, Typography } from "antd"
import { round } from "shared/utils"


export type KeyValueItemProps = {
    label: React.ReactNode,
    value: React.ReactNode
}

export type KeyNumberItemProps = {
    label: string,
    value?: number,
    postfix?: string,
    style?: React.CSSProperties
}

const { Text } = Typography;

export const KeyValueItem = (props: KeyValueItemProps) => {
    return <Flex className="flex justify-between" >
        {typeof props.label === 'string' ? <div>{props.label}</div> : props.label}
        {typeof props.value === 'string' ? <div>{props.value}</div> : props.value}
    </Flex>
}

export const KeyNumberItem = (props: KeyNumberItemProps) => {
    return <Flex className="flex justify-between" >
        <div style={props.style}>{props.label}</div>
        <Text style={props.style} type={(props.value??0)<0?"danger": "success"} >{round(props.value??0, 3)}{props.postfix && props.postfix}</Text>
    </Flex>
}