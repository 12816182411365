import React from 'react';

export const NoInternetPage = () => {
  return (
    <div>
      <h1>No Internet Connection</h1>
      <p>Please check your network connection and try again.</p>
    </div>
  );
};
