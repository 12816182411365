export type DateDiffResult ={
    years: number,
    months: number,
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
  }
export function getTimeDiffFromSeconds(milliseconds:number): DateDiffResult {  
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30); // ~30
    const years = Math.floor(days / 365); // ~365
  
    return {
      years,
      months: months % 12,
      days: days % 30,
      hours: hours % 24,
      minutes: minutes % 60,
      seconds: seconds % 60
    };
  }

  export function getTimeDiff(startDate:Date, endDate:Date): DateDiffResult {
    const diff = endDate.getTime() - startDate.getTime();
    return getTimeDiffFromSeconds(diff);
  }
export const getTimeDiffStr = (startDate:Date, endDate:Date): string =>
{
    const d = getTimeDiff(startDate, endDate);
    return `${d.years>0?(`${d.years}г `):''}${d.months>0? (`${d.months}мес. `):''}${d.days>0? (`${d.days}д `):''}${d.hours >0? (`${d.hours}ч `):''}${d.minutes>0? (`${d.minutes}мин `):''}`
}

export const getTimeDiffSecondsStr = (milliseconds:number): string =>
{
    const d = getTimeDiffFromSeconds(milliseconds);
    return `${d.years>0?(`${d.years}г `):''}${d.months>0? (`${d.months}мес. `):''}${d.days>0? (`${d.days}д `):''}${d.hours >0? (`${d.hours}ч `):''}${d.minutes>0? (`${d.minutes}мин `):''}`
}