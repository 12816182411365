/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ObjectArrayArrayResponseData } from '../models/ObjectArrayArrayResponseData';
import type { SliceDtoListResponseData } from '../models/SliceDtoListResponseData';
import type { SliceDtoResponseData } from '../models/SliceDtoResponseData';
import type { StringArrayResponseData } from '../models/StringArrayResponseData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OptionSliceService {
    /**
     * Получить список CEX, по которым можно получить срез опционов
     * @returns ObjectArrayArrayResponseData Success
     * @throws ApiError
     */
    public static getApiOptionsliceExchanges(): CancelablePromise<ObjectArrayArrayResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/optionslice/exchanges',
        });
    }
    /**
     * Получить список ассетов, по которым можно получить срез опционов.
     * @returns StringArrayResponseData Success
     * @throws ApiError
     */
    public static getApiOptionsliceAssets({
        cexCode,
        onlyLive = true,
    }: {
        /**
         * CEX, для которого мы получаем
         */
        cexCode?: number,
        /**
         * Только неэкспирированные опционы
         */
        onlyLive?: boolean,
    }): CancelablePromise<StringArrayResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/optionslice/assets',
            query: {
                'cexCode': cexCode,
                'onlyLive': onlyLive,
            },
        });
    }
    /**
     * Получить список дат экспираций, по которым можно получить срез опционов.
     * @returns StringArrayResponseData Success
     * @throws ApiError
     */
    public static getApiOptionsliceExpirationdates({
        cexCode,
        asset,
        onlyLive = true,
    }: {
        /**
         * CEX, для которого мы получаем список
         */
        cexCode?: number,
        /**
         * Asset, для которого мы получаем список
         */
        asset?: string,
        /**
         * Только неэкспирированные опционы
         */
        onlyLive?: boolean,
    }): CancelablePromise<StringArrayResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/optionslice/expirationdates',
            query: {
                'cexCode': cexCode,
                'asset': asset,
                'onlyLive': onlyLive,
            },
        });
    }
    /**
     * Получить срез опционов с фильтрацией по дате экспирации
     * @returns SliceDtoResponseData Success
     * @throws ApiError
     */
    public static getApiOptionsliceSlice({
        cexCode,
        asset,
        expirationDate,
    }: {
        /**
         * CEX, для которого мы получаем список
         */
        cexCode?: number,
        /**
         * Asset, для которого мы получаем список
         */
        asset?: string,
        /**
         * Дата экспирации
         */
        expirationDate?: string,
    }): CancelablePromise<SliceDtoResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/optionslice/slice',
            query: {
                'cexCode': cexCode,
                'asset': asset,
                'expirationDate': expirationDate,
            },
        });
    }
    /**
     * Получить срез опционов без фильтрации по дате экспирации
     * @returns SliceDtoListResponseData Success
     * @throws ApiError
     */
    public static getApiOptionsliceSliceAll({
        cexCode,
        asset,
    }: {
        /**
         * CEX, для которого мы получаем список
         */
        cexCode?: number,
        /**
         * Asset, для которого мы получаем список
         */
        asset?: string,
    }): CancelablePromise<SliceDtoListResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/optionslice/slice/all',
            query: {
                'cexCode': cexCode,
                'asset': asset,
            },
        });
    }
}
