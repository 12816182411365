import { ScenarioService, RoutePaths, ScenarioDto } from "shared";
import { CheckCircleOutlined, StopOutlined, WarningOutlined } from "@ant-design/icons";
import { HideTgInterface, Lists } from "feature";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Flex, Typography } from "antd";

const { Text } = Typography;

export const ScenarioList = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [Scenarios, setScenarios] = useState<ScenarioDto[]>([]);
  useEffect(() => {
    ScenarioService.getApiScenario().then((result) => {
      setScenarios(result?.data ?? []);
      setLoading(false);
    });
  }, []);

  const onAdd = ()=>{
    localStorage.removeItem('firstStepData');
    localStorage.removeItem('secondStepData');
    localStorage.removeItem('dictionaries');
    navigate(RoutePaths.Scenarios.Create.First.Path)
  }

  const Title = ({ name, swapper, isActive, feeTier }: any) => {
    return (
      <Flex className="flex justify-between" vertical={false}>
        <div>{name}</div>
        <Flex>
          {isActive && <CheckCircleOutlined style={{ color: "#3495eb" }} />}
          {!isActive && <StopOutlined style={{ color: "#8a8787" }} />}
        </Flex>
      </Flex>
    );
  };

  const Body = ({ swapper, feeTier, amount, token0, token1, strategyDescription }: ScenarioDto) => {
    return (
      <Flex vertical>
        <Flex className="flex justify-between" vertical={false}>
          <Flex className="items-baseline gap-x-2">
            <Text type="secondary">{swapper.name}</Text>
            <Text code>{(feeTier ?? 0) / 10000}%</Text>
          </Flex>
          <Flex>
            <Text type="secondary">{`${token0.symbol}-${token1.symbol}`}</Text>
          </Flex>
        </Flex>
        {
          Object.keys(strategyDescription!).map((key: string) => (
            <Flex className="flex justify-between" vertical={false} key={key}>
              <Text>{key}:</Text>
              <Text strong style={{color:"#beff00"}} >{strategyDescription![key]}</Text>
            </Flex>
          ))
        }
      </Flex>
    );
  };

  return (
    <>
      <Lists
        title="Сценарии"
        loading={loading}
        data={Scenarios.map((d) => {
          return {
            key: d.id!,
            title: Title(d),
            //description:<div></div>
            description: Body(d),
          };
        })}
        onClick={(item) => navigate(`/scenarios/${item.key}`)}
        onAddClick={onAdd}
      />
      <HideTgInterface />
    </>
  );
};
