/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DashboardResponseData } from '../models/DashboardResponseData';
import type { EarningsDtoPagedResultResponseData } from '../models/EarningsDtoPagedResultResponseData';
import type { EarningsDtoResponseData } from '../models/EarningsDtoResponseData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EarningsService {
    /**
     * @returns DashboardResponseData Success
     * @throws ApiError
     */
    public static getApiEarningsDashboard({
        scenarioId,
        sessionId,
        positionId,
    }: {
        scenarioId?: number,
        sessionId?: number,
        positionId?: number,
    }): CancelablePromise<DashboardResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/earnings/dashboard',
            query: {
                'scenarioId': scenarioId,
                'sessionId': sessionId,
                'positionId': positionId,
            },
        });
    }
    /**
     * @returns EarningsDtoPagedResultResponseData Success
     * @throws ApiError
     */
    public static getApiEarnings({
        page,
        size,
        orderBy,
        filter,
        searches,
    }: {
        /**
         * Номер страницы, начиная с 1
         */
        page?: number,
        /**
         * Размер страницы
         */
        size?: number,
        orderBy?: Array<string>,
        filter?: Array<string>,
        searches?: Array<string>,
    }): CancelablePromise<EarningsDtoPagedResultResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/earnings',
            query: {
                'Page': page,
                'Size': size,
                'OrderBy': orderBy,
                'Filter': filter,
                'Searches': searches,
            },
        });
    }
    /**
     * @returns EarningsDtoResponseData Success
     * @throws ApiError
     */
    public static getApiEarnings1({
        earningId,
    }: {
        earningId: number,
    }): CancelablePromise<EarningsDtoResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/earnings/{earningId}',
            path: {
                'earningId': earningId,
            },
        });
    }
}
