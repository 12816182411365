/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ObjectResponseData } from '../models/ObjectResponseData';
import type { ScenarioDtoListResponseData } from '../models/ScenarioDtoListResponseData';
import type { ScenarioDtoResponseData } from '../models/ScenarioDtoResponseData';
import type { ScenarioSimpleDto } from '../models/ScenarioSimpleDto';
import type { StringArrayResponseData } from '../models/StringArrayResponseData';
import type { StringAttributeDescriptionDictionaryResponseData } from '../models/StringAttributeDescriptionDictionaryResponseData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ScenarioService {
    /**
     * Получить список сценариев
     * @returns ScenarioDtoListResponseData Success
     * @throws ApiError
     */
    public static getApiScenario(): CancelablePromise<ScenarioDtoListResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/scenario',
        });
    }
    /**
     * Создать сценарий
     * @returns ObjectResponseData Success
     * @throws ApiError
     */
    public static postApiScenario({
        requestBody,
    }: {
        requestBody?: ScenarioSimpleDto,
    }): CancelablePromise<ObjectResponseData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/scenario',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновить сценарий
     * @returns ObjectResponseData Success
     * @throws ApiError
     */
    public static putApiScenario({
        requestBody,
    }: {
        requestBody?: ScenarioSimpleDto,
    }): CancelablePromise<ObjectResponseData> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/scenario',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Получить список сценариев
     * @returns ScenarioDtoResponseData Success
     * @throws ApiError
     */
    public static getApiScenario1({
        id,
    }: {
        id: number,
    }): CancelablePromise<ScenarioDtoResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/scenario/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Получить список стратегий
     * @returns StringArrayResponseData Success
     * @throws ApiError
     */
    public static getApiScenarioStrategies(): CancelablePromise<StringArrayResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/scenario/strategies',
        });
    }
    /**
     * Получить описание параметров стратегии
     * @returns StringAttributeDescriptionDictionaryResponseData Success
     * @throws ApiError
     */
    public static getApiScenarioStrategy({
        name,
    }: {
        name: string,
    }): CancelablePromise<StringAttributeDescriptionDictionaryResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/scenario/strategy/{name}',
            path: {
                'name': name,
            },
        });
    }
    /**
     * Запуск сценария
     * @returns ObjectResponseData Success
     * @throws ApiError
     */
    public static postApiScenarioStart({
        scenarioId,
    }: {
        scenarioId: number,
    }): CancelablePromise<ObjectResponseData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/scenario/start/{scenarioId}',
            path: {
                'scenarioId': scenarioId,
            },
        });
    }
    /**
     * Остановка сценария
     * @returns ObjectResponseData Success
     * @throws ApiError
     */
    public static postApiScenarioStop({
        scenarioId,
    }: {
        scenarioId: number,
    }): CancelablePromise<ObjectResponseData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/scenario/stop/{scenarioId}',
            path: {
                'scenarioId': scenarioId,
            },
        });
    }
}
