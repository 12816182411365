/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanResponseData } from '../models/BooleanResponseData';
import type { ObjectResponseData } from '../models/ObjectResponseData';
import type { StringResponseData } from '../models/StringResponseData';
import type { TokenResponseResponseData } from '../models/TokenResponseResponseData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthService {
    /**
     * @returns StringResponseData Success
     * @throws ApiError
     */
    public static getApiAuthQrcode(): CancelablePromise<StringResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/qrcode',
        });
    }
    /**
     * @returns ObjectResponseData Success
     * @throws ApiError
     */
    public static postApiAuthQrcodeConfirm({
        sessionId,
    }: {
        sessionId: string,
    }): CancelablePromise<ObjectResponseData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/qrcode/confirm/{sessionId}',
            path: {
                'sessionId': sessionId,
            },
        });
    }
    /**
     * @returns TokenResponseResponseData Success
     * @throws ApiError
     */
    public static postApiAuthQrcodeLogin({
        sessionId,
    }: {
        sessionId: string,
    }): CancelablePromise<TokenResponseResponseData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/qrcode/login/{sessionId}',
            path: {
                'sessionId': sessionId,
            },
        });
    }
    /**
     * @returns BooleanResponseData Success
     * @throws ApiError
     */
    public static getApiAuthQrcodeCheck({
        sessionId,
    }: {
        sessionId: string,
    }): CancelablePromise<BooleanResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/qrcode/check/{sessionId}',
            path: {
                'sessionId': sessionId,
            },
        });
    }
}
