import { ScenarioDto, SliceDto } from "shared/api";
import { CalculationResult, LiquidityResult, OptimizeOptions, Point } from "../model";
import { round } from "shared/utils";

  
  export function getLiquidityByAmountX(x: number, price: number, priceHigh: number): number {
    const L = (x * Math.sqrt(price) * Math.sqrt(priceHigh)) / (Math.sqrt(priceHigh) - Math.sqrt(price));
    return L;
  }
  
  export function getLiquidityByAmountY(y: number, price: number, priceLow: number): number {
    if (price === priceLow) {
      return 0;
    }
    const L = y / (Math.sqrt(price) - Math.sqrt(priceLow));
    return L;
  }
  
  export function getX(L: number, price: number, priceHigh: number): number {
    if (price === priceHigh) {
      return 0;
    }
    const x = L * ((Math.sqrt(priceHigh) - Math.sqrt(price)) / (Math.sqrt(price) * Math.sqrt(priceHigh)));
    return x;
  }
  
  export function getY(L: number, price: number, priceLow: number): number {
    const y = L * (Math.sqrt(price) - Math.sqrt(priceLow));
    return y;
  }
  
  export function getAmountsAtRangeEnds(L: number, priceLow: number, priceHigh: number): void {
    const tmp1 = getX(L, priceLow, priceHigh) / 10 ** 12;
    const tmp2 = getY(L, priceHigh, priceLow) / 10 ** 12;
    console.log(`${tmp1.toFixed(6)} ETH, если ↓ / ${tmp2.toFixed(6)} USDT если ↑`);
  }
  
  export function getAmountsAtRangeCenter(L: number, priceLow: number, priceHigh: number): void {
    const centerPrice = priceLow + (priceHigh - priceLow) / 2;
    const tmp1 = getX(L, centerPrice, priceHigh) / 10 ** 12;
    const tmp2 = getY(L, centerPrice, priceLow) / 10 ** 12;
    console.log(`${tmp1.toFixed(6)} ETH, если ↓ / ${tmp2.toFixed(6)} USDT если ↑`);
  }
  
  export  function getAmountsToInit(x: number, y: number, price: number, priceLow: number, priceHigh: number): LiquidityResult | null {
    if (x * y !== 0) {
      throw new Error("Должно быть x = 0 или y = 0, расчет происходит только по одному активу");
    }
  
    const accuracyLevel = 11235;
    const accuracy = 0.1;
    const correspondingToAccuracy: LiquidityResult[] = [];
  
    for (let inspNum = 0; inspNum <= accuracyLevel; inspNum++) {
      const amountRatio = inspNum / accuracyLevel;
  
      if (x !== 0) {
        const yOut = parseFloat((x * amountRatio * price).toFixed(10));
        const xOut = parseFloat((x * (1 - amountRatio)).toFixed(10));
        const L = getLiquidityByAmountX(xOut, price, priceHigh) * 10 ** 12;
        const yCalculated = getY(L, price, priceLow) / 10 ** 12;
  
        if (yCalculated > yOut) {
          continue;
        }
  
        const diffVal = yOut - yCalculated;
  
        if (yCalculated > 0 && diffVal / yCalculated < accuracy) {
          correspondingToAccuracy.push({ calc_accuracy: diffVal / yCalculated, liquidity: L, x: xOut, y: yCalculated });
        }
      } else if (y !== 0) {
        const xOut = parseFloat((y * amountRatio / price).toFixed(10));
        const yOut = parseFloat((y * (1 - amountRatio)).toFixed(10));
        const L = getLiquidityByAmountY(yOut, price, priceLow) * 10 ** 12;
        const xCalculated = getX(L, price, priceHigh) / 10 ** 12;
  
        if (xCalculated > xOut) {
          continue;
        }
  
        const diffVal = xOut - xCalculated;
  
        if (xCalculated > 0 && diffVal / xCalculated < accuracy) {
          correspondingToAccuracy.push({ calc_accuracy: diffVal / xCalculated, liquidity: L, x: xCalculated, y: yOut });
        }
      }
    }
  
    if (correspondingToAccuracy.length > 0) {
      return correspondingToAccuracy.reduce((prev, curr) => (prev.calc_accuracy < curr.calc_accuracy ? prev : curr));
    }
  
    return null;
  }

  
  
  export function calculate(
    amount: number,
    priceOnInit: number,
    priceLow: number,
    priceHigh: number,
    hedgeAmount: number,
    strikePrice: number,
    optionPrice: number,
    expiryDaysCount: number,
    feePerDay: number
  ): Point[] {
    let balance = amount;
    let x = 0; // ETH
    let y = balance; // USDT
    const step = 5;
    const result = getAmountsToInit(x, y, priceOnInit, priceLow, priceHigh);
    const feePerDayValue = amount * (feePerDay/100)
    const points: Point[] = [];  
    if (!result) {
      throw new Error("No valid result found for initialization");
    }
  
    for (let price = priceLow; price <= priceHigh; price += step) {
      const amountX = getX(result.liquidity, price, priceHigh) / 10 ** 12; // 18-6
      const amountY = getY(result.liquidity, price, priceLow) / 10 ** 12; // 18-6
      balance = amountX * price + amountY;
  
      const hedgeConditionValue = hedgeAmount * (strikePrice - price);
      const hedgeSum = optionPrice * hedgeAmount;
      const hedgeValue = (hedgeConditionValue < 0 ? -hedgeSum : hedgeConditionValue - hedgeSum) + feePerDayValue * expiryDaysCount;  
      const totalValue = amountX * price + amountY + hedgeValue;
      const pnl = totalValue - amount;
      points.push({ price, pnl});
    }
  
    return points;
  }

  // Поиск оптимального размера хеджа
  export function optimizeHedgeAmount(
    amount: number,
    priceOnInit: number,
    priceLow: number,
    priceHigh: number,
    strikePrice: number,
    optionPrice: number,
    expiryDaysCount: number,
    feePerDay: number,
    options: OptimizeOptions
  ): [number, Point[]] {
    let low = options.low
    let high = options.high
    let mid = (low + high) / 2.0;
    let iteration = 0;
    let result:Point[] = []
    // Используем метод бисекции для поиска оптимального значения
    while (iteration < options.maxIterations) {
      result = calculate(amount, priceOnInit, priceLow, priceHigh, mid, strikePrice, optionPrice, expiryDaysCount, feePerDay);      
      const leftPnl = result[0].pnl;
      const rightPnl = result[result.length - 1].pnl;
      const difference = leftPnl - rightPnl;
  
      if (Math.abs(difference) <= options.tolerance) {
        break;
      }
      if (Math.abs(low - high) <= options.tolerance) {
        mid =  high - mid <= options.tolerance? high:low
        break;
      }
      if (difference < 0) {
        low = mid;
      } else {
        high = mid;
      }
  
      mid = (low + high) / 2.0;
      iteration += 1;
    }  
    return [mid, result];
  }

  export const calculate_best = (state: ScenarioDto, poolPrice: number, data: SliceDto[]): CalculationResult[] => {
    const currentPrice = round(poolPrice, 2);
    const priceRange = currentPrice * (state.strategy.ChannelWidthPercent / 100);
    const high = round(currentPrice + priceRange * ((100 - state.strategy.ChannelEntryOffset) / 100), 2);
    const low = round(currentPrice - priceRange * ((state.strategy.ChannelEntryOffset) / 100), 2);
    const options: OptimizeOptions = {
        low: 0.001,
        high: 1000,
        maxIterations: 100,
        tolerance: 0.1
    }
    const list: CalculationResult[] = []
    let maxPnlItem: any = {}
    if (data && data.length > 0) {
        let hedge_amount = 0;
        data?.forEach(slice => {

            const expiration_date = Date.parse(slice.expirationDateString + 'T00:00:00Z');
            const days_to_expiration = Math.ceil((expiration_date - (new Date() as any)) / 24 / 3600 / 1000);
            let points: Point[] = [];
            slice.sliceTable = slice.sliceTable?.reverse() // для корректного расчета объема хеджирования
            slice.sliceTable?.filter(x =>
                x.type === "PUT"
            ).forEach(x => {

                if (x.strikePrice! > currentPrice && x.highestBidPrice && x.highestBidPrice > 0 && x.highestBidPrice! * hedge_amount < state.amount * 0.2) {
                    if (hedge_amount === 0) {
                        const [h, p] = optimizeHedgeAmount(
                            state.amount,
                            currentPrice,
                            low,
                            high,
                            x.strikePrice!,
                            x.highestBidPrice!,
                            days_to_expiration,
                            0,
                            options);
                        hedge_amount = h;
                        points = p;
                    }
                    else {
                        points = calculate(state.amount,
                            currentPrice,
                            low,
                            high,
                            hedge_amount,
                            x.strikePrice!,
                            x.highestBidPrice!,
                            days_to_expiration,
                            0);
                    }
                    const item = {
                        expirationDate: slice.expirationDateString,
                        item: x,
                        hedgeUnits: hedge_amount,
                        maxPnl: Math.max(...points.map(p => p.pnl)),
                        kpd: 0,
                        points: points,
                        daysToExpiration: days_to_expiration
                    };
                    item.kpd = (item.maxPnl / x.highestBidPrice!) * days_to_expiration

                    if (item.hedgeUnits < options.high && (maxPnlItem || item.maxPnl > maxPnlItem?.maxPnl)) {
                        maxPnlItem = item
                    }
                    list.push(item);
                }
            })
        });
        list.sort((a, b) => b.kpd - a.kpd);
        return list.slice(0, 10).map((item, index) => ({ ...item, rank: index + 1 }))
    }
    return [];
}