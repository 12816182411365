import { ReactNode, useEffect, useState } from "react";
import { HideTgInterface, Lists } from "feature";
import { EarningsService, EarningsDto, getEarningName } from "shared";
import { Space, Typography } from 'antd';
const { Text, Link } = Typography;

const EarningBody = (item: EarningsDto): ReactNode => {
  return (<div>
    <div>
      <Text type={(item.value && (item.value > 0) ? "success" : "danger")}>{item.usdValue} USDT</Text>
      {item.currencyName !== "USDT" && (<Text type="secondary">  ({item.value} {item.currencyName})</Text>)}
    </div>
    {item.txUrl && (<Link href={item.txUrl} target="_blank">
      {item.tx}
    </Link>)}
    <div>{(new Date(item.createdDate!)).toLocaleString()}</div>
  </div>)
}

export const EarningList = (props: any) => {
  const [loading, setLoading] = useState(true);

  const [earnings, setEarnings] = useState<EarningsDto[]>([]);
  useEffect(() => {
    const filter: string[] = []
    if (props?.positionId) filter.push(`positionId:${props?.positionId}`);
    if (props?.sessionId) filter.push(`sessionId:${props?.sessionId}`);
    if (props?.scenarioId) filter.push(`scenarioId:${props?.scenarioId}`);
    EarningsService.getApiEarnings(filter.length > 0 ? { filter: filter } : {}).then((result) => {
      setEarnings(result?.data?.queryable ?? []);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Lists
        title={props.title}
        loading={loading}
        data={earnings.map((d) => {
          return {
            key: d.id!,
            title: <div><Text>{getEarningName(d.earningsType!)} </Text> {d.description && <Text type="secondary">{d.description ? (" " + d.description) : ""}</Text>}</div>,
            description: EarningBody(d),
          };
        })}
      />
      {props.title && <HideTgInterface />}
    </>
  );
};
