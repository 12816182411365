import { CheckCircleOutlined, CloseCircleOutlined, FireOutlined, MinusCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Flex, Space, Tag, Card, Typography, Tabs } from "antd";
import { ListDataModel, Lists, Screen } from "feature";
import { EarningList } from "../../../earnings/ui";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useShowConfirm, PositionInfo, PositionService, OrderDto, useShowPopup, ScenarioService, ScenarioDto, SessionService, SessionDto } from "shared";
import { DashboardWidget } from "widgets/dashboard";
import { PositionList } from "pages/mobile/positions/ui";
const { Text, Title } = Typography;

export const ScenarioView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const showConfirm = useShowConfirm();
  const showPopUp = useShowPopup();

  const [loading, setLoading] = useState<boolean>(true);
  const [scenario, setScenario] = useState<ScenarioDto>();
  const [sessions, setSessions] = useState<SessionDto[]>();
  useEffect(() => {
    const scenarioRequest = ScenarioService.getApiScenario1({ id: Number.parseInt(id!) }).then(
      (result) => {
        setScenario(result?.data);
      }
    );
    const sessionsRequest = SessionService.getApiSession({ scenarioId: Number.parseInt(id!), activeOnly: true }).then(
      (result) => {
        setSessions(result?.data ?? []);
      }
    );
    Promise.all([scenarioRequest, sessionsRequest]).then((results) => {
      setLoading(false);
    })

  }, [id]);

  const onDelete = () => {
    if (scenario?.isActive === false) return;
    showConfirm("Остановить сценарий?", async (isOk) => {
      if (isOk) {
        setLoading(true)
        try {
          const response = await ScenarioService.postApiScenarioStop({
            scenarioId: Number.parseInt(id!),
          })
          if (response && !response?.isSuccess) {
            return showPopUp({
              title: "Ошибка",
              message: response?.errorText ?? "",
            });
          }
          else {
            navigate(-1);
          }
        }
        catch (error: any) {
          if (error) {
            return showPopUp({ title: "Ошибка", message: error.message });
          }
        }
        finally {
          setLoading(false)
        }

      }
    });
  };
  const onOpen = () => {
    if (scenario?.isActive === true) return;
    showConfirm("Запустить сценарий?", async (isOk) => {
      if (isOk) {

        setLoading(true)
        try {
          const response = await ScenarioService.postApiScenarioStart({
            scenarioId: Number.parseInt(id!),
          });
          if (response && !response?.isSuccess) {
            return showPopUp({
              title: "Ошибка",
              message: response?.errorText ?? "",
            });
          }
          else {
            navigate(-1);
          }
        }
        catch (error: any) {
          if (error) {
            return showPopUp({ title: "Ошибка", message: error.message });
          }
        }
        finally {
          setLoading(false)
        }
      }
    });
  };

  const dropError = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    showConfirm("Сбросить ошибку?", (isOk) => {
      if (isOk) {
        PositionService.deleteApiPositionError({
          positionId: Number.parseInt(id!),
        }).then((result) => {
          navigate(0);
        });
      }
    });
  };

  const onEdit = () => {
    navigate(`/scenarios/edit/${id}`, { state: scenario });
  };

  const Status = () => {
    return <Space size={[0, 8]} wrap>
      {scenario?.isActive ?
        (<Flex>
          <Tag icon={<CheckCircleOutlined />} color="success">В работе</Tag>
          {sessions && sessions.length > 0 && <Text>{sessions[0].status}</Text>}
        </Flex>
        ) :
        (
          <Tag icon={<MinusCircleOutlined />} color="default">Остановлен</Tag>
        )}

      {/*scenario?.isError && <Tag icon={<CloseCircleOutlined />} color="error" closeIcon onClose={dropError}>Ошибка</Tag>*/}
    </Space>;
  }

  const Order = (d: OrderDto): ListDataModel => {
    return {
      key: d.id!,
      title: <div>
        <Text type={d.positionSide === "Long" ? "success" : "danger"} >
          {`${(d.positionSide === "Long" && d.orderSide === "Buy") || (d.positionSide === "Short" && d.orderSide === "Sell") ? "Open" : "Close"} ${d.positionSide}`}
        </Text> {d.isFilled && <FireOutlined twoToneColor={"orange"} />}</div>,
      description: <Flex gap={5} justify="space-between">
        <Text strong>Price: {d.price}</Text>
        <Text type="secondary" italic>{(new Date(d.createdDate!)).toLocaleString()}</Text>
      </Flex>,
      style: { borderColor: d.isPlaced ? (d.isFilled ? "#87d068" : "#2db7f5") : "gray" }
    };
  }

  const tabs = [
    {
      key: '1',
      label: 'Позиции в пуле',
      children: <PositionList scenarioId={scenario?.id!} activeOnly={false} mode="inline"/>,
    },
    {
      key: '2',
      label: 'Доходы/Расходы',
      children: <EarningList scenarioId={scenario?.id} />,
    },
    /*
    {
      key: '2',
      label: 'Ордера',
      children: <Lists loading={false} data={scenario?.orders?.filter(o => !o.closedDate).map((d) => Order(d)) ?? []} />,
    },
    {
      key: '3',
      label: 'История',
      children: <Lists loading={false} data={scenario?.orders?.map((d) => Order(d)) ?? []} />,
    },*/
  ]

  const scenarioShortInfo = (scenario: ScenarioDto) => {
    return (
      <Flex vertical style={{ height: "100%" }}>
        <Card
          bordered
          size="small"         
          className="w-full rounded-xl"
          style={{height: "auto" }}
          >
          <Flex gap={3} vertical >
            <Flex gap={5} >
              <Text >Статус:</Text><Status />
            </Flex>
            <Flex vertical>
            {Object.keys(scenario.strategyDescription!).map((key: string) => (
                <Flex className="flex justify-between" vertical={false} key={key}>
                  <Text>{key}:</Text>
                  <Text strong style={{ color: "#beff00" }} >{scenario.strategyDescription![key]}</Text>
                </Flex>
              ))}
            </Flex>
            <Flex style={{ height: "110px", paddingBottom: "15px" }}>
              <DashboardWidget scenarioId={Number.parseInt(id!)} height="100%" />
            </Flex>
          </Flex>

        </Card>
        <Tabs defaultActiveKey="1" centered items={tabs} />
      </Flex>)
  }

  const extra = 
    (!scenario)
      ? undefined
      : { onClick: onEdit };

  return (
     <Screen loading={loading}
      btn={scenario?.isActive === false ?
        {
          type: "primary",
          title: "Запустить сценарий",
          disabled:  false,          
          onClick: onOpen,
        } : {
          type: "danger",
          title: "Остановить сценарий",
          disabled: false,
          onClick: onDelete,
        }}
      title={`${scenario?.name??""}`}
      extra={extra} >
      <div>
        {scenario && scenarioShortInfo(scenario!)}
      </div>
    </Screen>
  );
};
