import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, Form, InputNumber } from "antd";
import { SubmitButton, MobileSelect } from "feature";
import {
  DictionaryService,
  ExchangeService,
  WalletService,
  RoutePaths,
  SwapperResponse,
  NetDto,
  TokenDto,
  SwapperPoolItem,
  LightExchangeResponse,
  ScenarioService,
} from "shared";
import {
  FirstFormFullModel,
  FirstFormModel,
  IResponseContainer,
  WalletModel,
} from "../../../model";

export const ScenarioCreatePartFirst = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm<FirstFormModel>();
  const swapperValue = Form.useWatch("swapper", form);
  const networkValue = Form.useWatch("network", form);
  const tokenFirstValue = Form.useWatch("tokenFirst", form);
  const tokenSecondValue = Form.useWatch("tokenSecond", form);

  const initialLoad = useRef(true); // For tracking initial load

  // State management
  const [swappers, setSwappers] = useState<SwapperResponse[] | null>(null);
  const [networks, setNetworks] = useState<NetDto[]>([]);
  const [tokensFirst, setTokensFirst] = useState<TokenDto[]>([]);
  const [tokensSecond, setTokensSecond] = useState<TokenDto[]>([]);
  const [pools, setPools] = useState<SwapperPoolItem[]>([]);
  const [wallets, setWallets] = useState<WalletModel[]>([]);
  const [exchange, setExchange] = useState<LightExchangeResponse[]>([]);
  const [strategyTypes, setStrategyTypes] = useState<string[]>([]);
  const [loading, setLoading] = useState<ILoading>({
    swapper: false,
    network: false,
    token: false,
    pool: false,
    wallet: false,
    exchange: false,
    strategy: false,
  });

  // Interfaces
  interface ILoading {
    swapper: boolean;
    network: boolean;
    token: boolean;
    pool: boolean;
    wallet: boolean;
    exchange: boolean;
    strategy: boolean;
  }

  // Function to set form values
  const setFormValues = (parsedData: FirstFormFullModel) => {
    form.setFieldsValue({
      swapper: parsedData.swapper.id,
      network: parsedData.network.id,
      wallet: parsedData.wallet.id,
      exchange: parsedData.exchange.id,
      pool: parsedData.pool.id,
      tokenFirst: parsedData.tokenFirst.id,
      tokenSecond: parsedData.tokenSecond.id,
      strategyType: parsedData.strategyType,
    });
  };

  // Fetch initial data
  const fetchInitialData = async () => {
    try {
      const savedData = localStorage.getItem("firstStepData");
      const savedDictionaries = localStorage.getItem("dictionaries");

      if (savedDictionaries) {
        const parsedDictionaries = JSON.parse(savedDictionaries);
        setSwappers(parsedDictionaries.swappers);
        setWallets(parsedDictionaries.wallets);
        setExchange(parsedDictionaries.exchange);
        setStrategyTypes(parsedDictionaries.strategyTypes);
        setNetworks(parsedDictionaries.networks);
        setTokensFirst(parsedDictionaries.tokensFirst);
        setTokensSecond(parsedDictionaries.tokensSecond);
        setPools(parsedDictionaries.pools);
        if (savedData) {
          const parsedData = JSON.parse(savedData);
          setTimeout(() => {
            setFormValues(parsedData);
          }, 0);          
        }
      } else {
        fetchSwappers();
        fetchWallets();
        fetchExchanges();
        fetchStrategyTypes();
      }
      setTimeout(() => {
        initialLoad.current = false;
      }, 1000);
    } catch (error) {
      console.error("Error loading initial data:", error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Handlers for fetching different sets of data
  const handleSwapperChange = async (swapperValue: number) => {
    setLoading({ ...loading, network: true });
    const result = await DictionaryService.getApiDictionaryNetworks({
      swapperId: swapperValue,
    });
    setNetworks(result.data!);
    setLoading({ ...loading, network: false });

    setTokensFirst([]);
    setTokensSecond([]);
    setPools([]);
    form.setFieldsValue({
      network: null,
      tokenFirst: null,
      tokenSecond: null,
      pool: null,
    });
  };

  const handleNetworkChange = async (networkValue: number) => {
    setLoading({ ...loading, token: true });
    const result = await DictionaryService.getApiDictionaryTokens({
      netId: networkValue, swapperId: swapperValue!
    });
    setTokensFirst(result.data?.filter((x) => !x.isStableCoin) ?? []);
    setTokensSecond(result.data?.filter((x) => x.isStableCoin) ?? []);
    setLoading({ ...loading, token: false });

    setPools([]);
    form.setFieldsValue({
      tokenFirst: undefined,
      tokenSecond: undefined,
      pool: undefined,
    });
  };

  const handleTokenChange = async (
    tokenFirstValue: number,
    tokenSecondValue: number
  ) => {
    setLoading({ ...loading, pool: true });
    const result = await DictionaryService.getApiDictionaryPools({
      netId: form.getFieldValue("network"),
      swapperId: form.getFieldValue("swapper"),
      token0Id: tokenFirstValue,
      token1Id: tokenSecondValue,
    });
    setPools(result.data!);
    setLoading({ ...loading, pool: false });
    form.setFieldsValue({
      pool: undefined,
    });
  };

  const fetchSwappers = async () => {
    setLoading({ ...loading, swapper: true });
    DictionaryService.getApiDictionarySwappers({}).then((result) => {
      setSwappers(result.data!);
      setLoading({ ...loading, swapper: false });
    });
  };

  const fetchWallets = async () => {
    setLoading({ ...loading, wallet: true });
    const result = await WalletService.getApiWallet();
    setWallets(result?.data ?? []);
    setLoading({ ...loading, wallet: false });
  };

  const fetchExchanges = async () => {
    setLoading({ ...loading, exchange: true });
    const result = await ExchangeService.getApiExchange();
    setExchange(result?.data!);
    setLoading({ ...loading, exchange: false });
  };

  const fetchStrategyTypes = async () => {
    setLoading({ ...loading, strategy: true });
    const result = await ScenarioService.getApiScenarioStrategies();
    setStrategyTypes(result?.data!);
    setLoading({ ...loading, strategy: false });
  };


  useEffect(() => {
    if (!initialLoad.current) {
      const swapperValue = form.getFieldValue("swapper");
      if (swapperValue) {
        handleSwapperChange(swapperValue);
      }
    }
  }, [swapperValue]);

  useEffect(() => {
    if (!initialLoad.current) {
      const networkValue = form.getFieldValue("network");
      if (networkValue) {
        handleNetworkChange(networkValue);
      }
    }
  }, [networkValue]);

  useEffect(() => {
    if (!initialLoad.current) {
      const tokenFirstValue = form.getFieldValue("tokenFirst");
      const tokenSecondValue = form.getFieldValue("tokenSecond");
      if (tokenFirstValue && tokenSecondValue) {
        handleTokenChange(tokenFirstValue, tokenSecondValue);
      }
    }
  }, [tokenFirstValue, tokenSecondValue]);

  const onFinish = (value: FirstFormModel) => {
    const fullState: FirstFormFullModel = {
      swapper: swappers?.find((x) => x.id === value.swapper)!,
      network: networks?.find((x) => x.id === value.network)!,
      wallet: wallets.find((x) => x.id === value.wallet)!,
      exchange: exchange.find((x) => x.id === value.exchange)!,
      pool: pools.find((x) => x.id === value.pool)!,
      tokenFirst: tokensFirst.find((x) => x.id === value.tokenFirst)!,
      tokenSecond: tokensSecond.find((x) => x.id === value.tokenSecond)!,
      strategyType: value.strategyType,
    };
    location.state = fullState;

    const dictionaries = {
      swappers,
      wallets,
      exchange,
      strategyTypes,
      networks,
      tokensFirst,
      tokensSecond,
      pools,
    };

    localStorage.setItem("dictionaries", JSON.stringify(dictionaries));
    localStorage.setItem("firstStepData", JSON.stringify(fullState)); // Save state to localStorage
    navigate(RoutePaths.Scenarios.Create.Second.Path, { state: fullState });
  };

  return (
    <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
      <Form.Item name="swapper" label="Обменник" rules={[{ required: true }]}>
        <MobileSelect
          loading={loading.swapper}
          options={swappers?.map((d) => ({
            value: d.id,
            label: d.name,
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item name="network" label="Сеть" rules={[{ required: true }]}>
        <MobileSelect
          loading={loading.network}
          disabled={!form.getFieldValue("swapper")}
          options={networks?.map((d) => ({
            value: d.id,
            label: d.name,
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item name="tokenFirst" label="Токен 1" rules={[{ required: true }]}>
        <MobileSelect
          loading={loading.token}
          disabled={!form.getFieldValue("network")}
          options={tokensFirst.map((d) => ({
            value: d.id,
            label: d.symbol,
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item name="tokenSecond" label="Токен 2" rules={[{ required: true }]}>
        <MobileSelect
          loading={loading.token}
          disabled={!form.getFieldValue("network")}
          options={tokensSecond.map((d) => ({
            value: d.id,
            label: d.symbol,
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item name="pool" label="Пул" rules={[{ required: true }]}>
        <MobileSelect
          loading={loading.pool}
          disabled={!form.getFieldValue("tokenFirst") || !form.getFieldValue("tokenSecond")}
          options={pools?.map((d) => ({
            value: d.id,
            label: d.name,
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item name="wallet" label="Кошель" rules={[{ required: true }]}>
        <MobileSelect
          options={wallets.map((d) => ({
            value: d.id,
            label: d.name || d.address,
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item name="exchange" label="Доступ" rules={[{ required: true }]}>
        <MobileSelect
          options={exchange.map((d) => ({
            value: d.id,
            label: d.name,
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item name="strategyType" label="Стратегия" rules={[{ required: true }]}>
        <MobileSelect
          options={strategyTypes.map((d) => ({
            value: d,
            label: d,
          }))}
          size="large"
        />
      </Form.Item>
      <SubmitButton form={form} text="Далее" isMain={true} />
    </Form>
  );
};
