/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BalanceInfoResponseData } from '../models/BalanceInfoResponseData';
import type { FileContent } from '../models/FileContent';
import type { StrategyType } from '../models/StrategyType';
import type { UserWalletDto } from '../models/UserWalletDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WalletService {
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiWallet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/wallet',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiWallet({
        requestBody,
    }: {
        requestBody?: UserWalletDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/wallet',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiWalletRaw(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/wallet/raw',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static putApiWalletRaw({
        requestBody,
    }: {
        requestBody?: FileContent,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/wallet/raw',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiWallet1({
        id,
    }: {
        id: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/wallet/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiWallet({
        id,
    }: {
        id: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/wallet/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static putApiWallet({
        id,
        name,
    }: {
        id: number,
        name?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/wallet/{id}',
            path: {
                'id': id,
            },
            query: {
                'name': name,
            },
        });
    }
    /**
     * @returns BalanceInfoResponseData Success
     * @throws ApiError
     */
    public static getApiWalletBalance({
        userWalletId,
        userExchangeId,
        strategyType,
        netId,
        tokens,
    }: {
        userWalletId?: number,
        userExchangeId?: number,
        strategyType?: StrategyType,
        netId?: number,
        tokens?: Array<number>,
    }): CancelablePromise<BalanceInfoResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/wallet/balance',
            query: {
                'userWalletId': userWalletId,
                'userExchangeId': userExchangeId,
                'strategyType': strategyType,
                'netId': netId,
                'tokens': tokens,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiWalletTypes(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/wallet/types',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiWalletCreateRaw({
        walletType,
        requestBody,
    }: {
        walletType: string,
        requestBody?: FileContent,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/wallet/create/raw/{walletType}',
            path: {
                'walletType': walletType,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static putApiWalletProxy({
        method,
        requestBody,
    }: {
        method: number,
        requestBody?: FileContent,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/wallet/proxy/{method}',
            path: {
                'method': method,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiWalletProxy({
        requestBody,
    }: {
        requestBody?: FileContent,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/wallet/proxy',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
