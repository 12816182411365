import "./index.scss";

interface IChartCell {
    points: number[];
}
//110 42 | 92 26
const ChartCell = ({ points }: IChartCell) => {
    const min = Math.min(...points, 0);
    const max = Math.max(...points, 0);

    const color =
        points[points.length - 1] > points[0]
            ? "#92D800"
            : "#FF2F61";

    const height = max - min;
    const width = height * (92 / 26);
    const multiplier = width / points.length;
    const strokeWidth = height / 16;

    const pointsStr = points
        .map((x, index) => `${index * multiplier} ${(max - x - min).toFixed(2)}`)
        .toString();

    return (
        <svg
            width={92}
            height={26}
            className="chart w-[92px] h-[26px] bg-bgColor"
            viewBox={`0 0 ${width} ${height}`}
        >
            <polyline
                points={pointsStr}
                stroke={color}
                strokeWidth={strokeWidth}
                fill="none"
            />
        </svg>
    );
};

export { ChartCell };