/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CurrencyCode {
    TON = 'TON',
    BTC = 'BTC',
    USDT = 'USDT',
    EUR = 'EUR',
    USD = 'USD',
    RUB = 'RUB',
}
