import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, Form, InputNumber } from "antd";
import { SubmitButton, MobileSelect } from "feature";
import {
  DictionaryService,
  ExchangeService,
  WalletService,
  RoutePaths,
  SwapperResponse,
  NetDto,
  TokenDto,
  SwapperPoolItem,
  LightExchangeResponse,
} from "shared";
import {
  FirstFormFullModel,
  FirstFormModel,
  IResponseContainer,
  WalletModel,
} from "../../../model";

export const PositionCreatePartFirst = () => {
  const [form] = Form.useForm<FirstFormModel>();
  form.setFieldsValue({
    hedgeInputPricePercent: 10,
    slippagePercent: 20,    
    useLongHedge: false,
    hedgeOnlyEdge: false
  });

  // Получить список обменников
  const [swapper, setSwapper] = useState<SwapperResponse[] | null>(null);
  useEffect(() => {
    setLoading({ ...loading, swapper: true });
    DictionaryService.getApiDictionarySwappers({}).then((result) => {
      setSwapper(result.data!);
      setLoading({ ...loading, swapper: false });
    });
  }, []);

  // Получить список сетей
  const [networks, setNetworks] = useState<NetDto[]>([]);
  const swapperValue = Form.useWatch("swapper", form);
  useEffect(() => {
    if (swapperValue) {
      setLoading({ ...loading, network: true });
      DictionaryService.getApiDictionaryNetworks({
        swapperId: swapperValue,
      }).then((result) => {
        setNetworks(result.data!);
        setLoading({ ...loading, network: false });

        setTokens([]);
        setTokensFirst([]);
        setTokensSecond([]);
        setPools([]);
        form.setFieldsValue({
          network: null,
          tokenFirst: null,
          tokenSecond: null,
          pool: null,
        });
      });
    }
  }, [swapperValue]);

  // Получить список токенов
  const [tokens, setTokens] = useState<TokenDto[]>([]);
  const [tokensFirst, setTokensFirst] = useState<TokenDto[]>([]);
  const [tokensSecond, setTokensSecond] = useState<TokenDto[]>([]);
  const networkValue = Form.useWatch("network", form);
  useEffect(() => {
    if (networkValue && swapperValue) {
      setLoading({ ...loading, token: true });
      DictionaryService.getApiDictionaryTokens({
        netId: networkValue,
        swapperId: swapperValue,
      }).then((result) => {
        setTokens(result.data!);
        setTokensFirst(
          result.data?.filter((x) => x.isStableCoin === false) ?? []
        );
        setTokensSecond(
          result.data?.filter((x) => x.isStableCoin === true) ?? []
        );
        setLoading({ ...loading, token: false });

        setPools([]);
        form.setFieldsValue({
          tokenFirst: undefined,
          tokenSecond: undefined,
          pool: undefined,
        });
      });
    }
  }, [networkValue, swapperValue]);

  // Получить список пулов
  const [pools, setPools] = useState<SwapperPoolItem[]>([]);
  const tokenFirstValue = Form.useWatch("tokenFirst", form);
  const tokenSecondValue = Form.useWatch("tokenSecond", form);
  useEffect(() => {
    if (tokenFirstValue && tokenSecondValue) {
      setLoading({ ...loading, pool: true });
      DictionaryService.getApiDictionaryPools({
        netId: networkValue!,
        swapperId: swapperValue!,
        token0Id: tokenFirstValue,
        token1Id: tokenSecondValue,
      }).then((result) => {
        setPools(result.data!);
        setLoading({ ...loading, pool: false });
        form.setFieldsValue({
          pool: undefined,
        });
      });
    }
  }, [tokenFirstValue, tokenSecondValue]);

  // Получить список кошельков
  const [wallets, setWallets] = useState<WalletModel[]>([]);
  useEffect(() => {
    setLoading({ ...loading, wallet: true });
    WalletService.getApiWallet().then(
      (result: IResponseContainer<WalletModel[]>) => {
        setWallets(result?.data ?? []);
        setLoading({ ...loading, wallet: false });
      }
    );
  }, []);

  // Получить список обменников
  const [exchange, setExchange] = useState<LightExchangeResponse[]>([]);
  useEffect(() => {
    setLoading({ ...loading, exchange: true });
    ExchangeService.getApiExchange().then((result) => {
      setExchange(result?.data!);
      setLoading({ ...loading, exchange: false });
    });
  }, []);

  const navigate = useNavigate();
  const onFinish = (value: FirstFormModel) => {
    const fullState: FirstFormFullModel = {
      swapper: swapper?.find((x) => x.id === value.swapper)!,
      network: networks?.find((x) => x.id === value.network)!,
      wallet: wallets.find((x) => x.id === value.wallet)!,
      exchange: exchange.find((x) => x.id === value.exchange)!,
      pool: pools.find((x) => x.id === value.pool)!,
      tokenFirst: tokens.find((x) => x.id === value.tokenFirst)!,
      tokenSecond: tokens.find((x) => x.id === value.tokenSecond)!,
      hedgeInputPricePercent: value.hedgeInputPricePercent!,
      slippagePercent: value.slippagePercent!,
      useLongHedge: value.useLongHedge!,
      hedgeOnlyEdge: value.hedgeOnlyEdge!,
    };
    navigate(RoutePaths.Positions.Create.Second.Path, { state: fullState });
  };

  interface ILoading {
    swapper: boolean;
    network: boolean;
    token: boolean;
    pool: boolean;
    wallet: boolean;
    exchange: boolean;
  }
  const [loading, setLoading] = useState<ILoading>({
    swapper: false,
    network: false,
    token: false,
    pool: false,
    wallet: false,
    exchange: false,
  });

  return (
    <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
      <Form.Item name="swapper" label="Обменник" rules={[{ required: true }]}>
        <MobileSelect
          loading={loading.swapper}
          options={swapper?.map((d) => ({
            value: d.id,
            label: d.name,
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item name="network" label="Сеть" rules={[{ required: true }]}>
        <MobileSelect
          loading={loading.network}
          disabled={!swapperValue}
          options={networks?.map((d) => ({
            value: d.id,
            label: d.name,
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item name="tokenFirst" label="Токен 1" rules={[{ required: true }]}>
        <MobileSelect
          loading={loading.token}
          disabled={!networkValue}
          options={tokensFirst.map((d) => ({
            value: d.id,
            label: d.symbol,
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item
        name="tokenSecond"
        label="Токен 2"
        rules={[{ required: true }]}
      >
        <MobileSelect
          loading={loading.token}
          disabled={!networkValue}
          options={tokensSecond.map((d) => ({
            value: d.id,
            label: d.symbol,
          }))}
          size="large"
        />
      </Form.Item>

      <Form.Item name="pool" label="Пул" rules={[{ required: true }]}>
        <MobileSelect
          loading={loading.pool}
          disabled={!tokenFirstValue || !tokenSecondValue}
          options={pools?.map((d) => ({
            value: d.id,
            label: d.name,
          }))}
          size="large"
        />
      </Form.Item>

      <Form.Item name="wallet" label="Кошель" rules={[{ required: true }]}>
        <MobileSelect
          options={wallets.map((d) => ({
            value: d.id,
            label: d.name || d.address
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item name="exchange" label="Доступ" rules={[{ required: true }]}>
        <MobileSelect
          options={exchange.map((d) => ({
            value: d.id,
            label: d.name,
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item
        name="hedgeInputPricePercent"
        label="Процент от цены канала для входа"
        rules={[{ required: true }]}
      >
        <InputNumber
          min={5}
          max={50}
          suffix="%"
          size="large"
          type="number"
          className="w-full"
        />
      </Form.Item>
      <Form.Item
        name="slippagePercent"
        label="Проскальзование %"
        rules={[{ required: true }]}
      >
        <InputNumber
          min={0}
          max={100}
          suffix="%"
          size="large"
          type="number"
          className="w-full"
        />
      </Form.Item>
      <Form.Item name="useLongHedge" valuePropName="checked">
        <Checkbox  >Использовать Long-хеджирование</Checkbox>
      </Form.Item>
      <Form.Item name="hedgeOnlyEdge" valuePropName="checked">
        <Checkbox  >Хеджировать только по краям (Вход по маркету)</Checkbox>
      </Form.Item>
      <SubmitButton form={form} text="Далее" isMain={true} />
    </Form>
  );
};
