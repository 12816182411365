import { EarningsType } from "..";

const earningTypes = new Map([
    ["SwapperPoolFee", "Доход пула"],
    ["ContractCallFee", "Вызов контракта"],
    ["ExchangeFundingFee", "Фандинг"],
    ["ExchangePnl", "Pnl хедж-позиции"],
    ["ExchangeFee", "Комиссия биржи"],
    ["AssetValueChange", "Стоимость актива"],
    ["ExchangeOptionBuyFee", "Комиссия биржи"],
    ["ExchangeOptionSellProfit", "PNL хедж-позиции" ],
    ["ExchangeOptionActivationProfit", "PNL экспирации опциона"],
  ]);

export const getEarningName = (type: EarningsType): string | undefined => earningTypes.get(type)