import { useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Form, InputNumber, Typography } from "antd";
import { SubmitButton } from "feature";
import { FirstFormFullModel, SecondFormModel } from "../../../model";
import { useEffect, useState } from "react";
import {
  BalanceInfo,
  DictionaryService,
  PositionDto,
  PositionService,
  WalletService,
  useShowPopup,
} from "shared";

export const PositionCreatePartSecond = () => {
  const [form] = Form.useForm<SecondFormModel>();

  const location = useLocation();
  const state = location.state as FirstFormFullModel;
  const offset = state.pool.currentPrice! * 0.1;

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const lower = state.pool.currentPrice! - offset;
    const upper = state.pool.currentPrice! + offset;
    const request = {
      netId: state.network.id!,
      swapperId: state.swapper.id!,
      token0Id: state.tokenFirst.id!,
      token1Id: state.tokenSecond.id!,
      feeTier: state.pool.feeTier!,
      lowerPrice: lower,
      upperPrice: upper,
    };

    DictionaryService.getApiDictionaryPoolAmount(request).then((result) => {
      form.setFieldsValue({
        lowerPrice: result?.data?.lowerPrice,
        upperPrice: result?.data?.upperPrice,
      });
      setLoading(false);
    });
  }, []);

  const [walletBalance, setWalletBalance] = useState<BalanceInfo>({});
  useEffect(() => {
    const request = {
      userWalletId: state.wallet.id!,
      userExchangeId: state.exchange.id,
      strategyType: undefined,
      netId: state.network.id!,
      tokens: [state.tokenFirst.id!, state.tokenSecond.id!]
    };

    WalletService.getApiWalletBalance(request).then((result) => {
      setWalletBalance(result?.data!);
    });
  }, []);

  const showPopUp = useShowPopup();
  const navigate = useNavigate();
  const onFinish = async (values: SecondFormModel) => {
    const request: PositionDto = {
      netId: state.network.id!,
      swapperId: state.swapper.id!,
      feeTier: state.pool.feeTier!,
      poolAddress: state.pool.id!,
      token0Id: state.tokenFirst.id!,
      token1Id: state.tokenSecond.id!,
      userExchangeId: state.exchange.id!,
      userWalletId: state.wallet.id,
      upperPrice: values.upperPrice,
      lowerPrice: values.lowerPrice,
      amount: values.amount,
      hedgeInputPricePercent: state.hedgeInputPricePercent,
      slippagePercent: state.slippagePercent,
      useLongHedge: state.useLongHedge,
      hedgeOnlyEdge: state.hedgeOnlyEdge,
      stopLossPercent: values.stopLossPercent,      
      leverage: values.leverage,
    };

    try {
      const response = await PositionService.postApiPosition({
        requestBody: request,
      });

      if (response && !response?.isSuccess) {
        return showPopUp({
          title: "Ошибка",
          message: response?.errorText ?? "",
        });
      }

      navigate(-2);
    } catch (error: any) {
      if (error) {
        return showPopUp({ title: "Ошибка", message: error.message });
      }
    }
  };

  type SizeType = "small" | "middle" | "large" | undefined;
  const props = {
    min: 0,
    size: "large" as SizeType,
    type: "number",
    className: "w-full",
    disabled: loading,
    suffix: <>{loading && <LoadingOutlined style={{ fontSize: 12 }} spin />}</>,
  };

  return (
    <>
      <Alert
        type="info"
        message={`*баланс ~ ${walletBalance} $`}
        className="mb-3"
      />
      <Typography.Title level={5} style={{ marginTop: 0 }}>
        Текущая цена: {JSON.stringify(state.pool.currentPrice)}
      </Typography.Title>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item
          name="lowerPrice"
          label="Низкая цена"
          rules={[{ required: true }]}
        >
          <InputNumber {...props} />
        </Form.Item>
        <Form.Item
          name="upperPrice"
          label="Высокая цена"
          rules={[{ required: true }]}
        >
          <InputNumber {...props} />
        </Form.Item>
        <Form.Item
          name="amount"
          label={"Сумма вклада"}
          rules={[{ required: true }]}
        >
          <InputNumber {...props} />
        </Form.Item>
        <Form.Item name="leverage" label={"Плечо"} rules={[{ required: true }]}>
          <InputNumber {...props} />
        </Form.Item>
        <Form.Item
          name="stopLossPercent"
          label={"Стоп-лосс в процентах"}
          rules={[{ required: true }]}
        >
          <InputNumber {...props} step={0.1} />
        </Form.Item>
        <Form.Item>
          <SubmitButton form={form} text="Сохранить" isMain={true} />
        </Form.Item>
      </Form>
    </>
  );
};
